import React from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"

const SMSType = ({ dataRowIndex }) => {
  return (
    <div className="col-lg-8 col-md-10 mx-auto mt-3 forThemOptionField FT_SMS">
      <div className="colHeading">
        <strong>
          <i className="bi bi-envelope-fill"></i>
          Autoresponder
        </strong>
      </div>
      <div className="dh-block-body">
        <div className="mb-2">
          <div className="d-inline-block">
            <button
              className="btn btnAddNewGreenDashed"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Inject Recipient Variable i.e. Firstname</Tooltip>}
              >
                <span>
                  <i className="bi bi-person-bounding-box"></i>
                </span>
              </OverlayTrigger>
            </button>
            <ul className="dropdown-menu scrollable-menu">
              <li>
                <a className="dropdown-item">Firstname_FirstCap</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mb-2">
          <textarea
            className="SMS_Msg form-control"
            rows="5"
            placeholder="Message"
            name={`sms_message_${dataRowIndex}`}
          ></textarea>
        </div>
        <div className="row">
          <div className="col-lg-6 text-start ChatDockPrivateNoteTools mt-1">
            <OverlayTrigger placement="top" overlay={<Tooltip>Add URL</Tooltip>}>
              <a href="#">
                <i className="bi bi-link-45deg" />
              </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Insert Image/Video</Tooltip>}>
              <a href="#">
                <i className="bi bi-camera-fill" />
              </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Upload file</Tooltip>}>
              <a href="#" className="chatNoteImg">
                <i className="bi bi-paperclip" />
              </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Inject Emoji</Tooltip>}>
              <a href="#">
                <i className="bi bi-emoji-smile" />
              </a>
            </OverlayTrigger>
          </div>
          <div className="col-lg-6 text-end">
            <button type="button" className="btn btn-primary mt-1">
              <i className="bi bi-send-fill"></i>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SMSType
{
  /* <div className="col-lg-12 mt-3 forThemOptionField FT_SMS">
<div className="colHeading">
  <strong>
    <i className="bi bi-envelope-fill" /> Autoresponder
  </strong>
</div>
<div className="dh-block-body">
  <div className="row">
    <div className="col align-self-end">
      <div className="smsContainer">
        <Form.Control
          as="textarea"
          cols="30"
          rows="5"
          name={`sms_message_${dataRowIndex}`}
          className="SMS_Msg form-control"
          placeholder="SMS Message here..."
        />
        <span className="Msg_counter float-end">280 remaining</span>
      </div>
    </div>
  </div>
</div>
</div> */
}
