import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import ContactDetail from "../../../components/Contacts/Detail/detail"
import ReduxWrapper from "../../../redux/reduxWrapper"
import queryString from "query-string"

const ContactGroup = props => {
  return (
    <PrivateRoute
      component={ContactDetail}
      location={props.location}
      selectedContactId={props.id}
      selectedEmail={queryString.parse(props.location.search).email}
      selectedSMS={queryString.parse(props.location.search).email}
    />
  )
}

const WrappedPage = props => <ReduxWrapper element={<ContactGroup {...props} />} />
export default WrappedPage

export const Head = () => <title>Contact Detail</title>
