import * as React from "react"
import Modal from "react-bootstrap/Modal"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../services/axiosInstance"
import { useEffect, useState } from "react"
import moment from "moment"
import { DATE_FORMAT } from "./eventHelper"
import {
  ASAP,
  taskStatusesData,
  taskTimeData,
  taskUrgencyData,
  ADD_NEW_CONTACT,
  SEARCH_EXISTING_CONTACTS,
} from "../Tasks/taskHelper"
import {
  PRIVACY_PUBLIC,
  DEFAULT_CONTACT_PHONE_OPTION,
  DEFAULT_CONTACT_ADDRESS_OPTION,
} from "../../services/globalStaticData"
import TaskContactForm from "../Tasks/Listing/taskContactForm"
import { CONTACT_TYPE_HUMAN } from "../Contacts/contactHelper"

const EventModal = ({
  showModal,
  setShowModal,
  selectedEventTime,
  selectedContactId,
  selectedContactName,
  selectedContactImage,
}) => {
  const [projectTypes, setProjectTypes] = useState([]),
    [defaultProjectType, setDefaultProjectType] = useState(""),
    [isSaveTask, setIsSaveTask] = useState(true),
    [status, setStatus] = useState(""),
    [statusIcon, setStatusIcon] = useState(""),
    [transactionTypeStatus, setTransactionTypeStatus] = useState(null),
    [transactionTypeId, setTransactionTypeId] = useState(""),
    [estimatedTime, setEstimatedTime] = useState("1 Hour"),
    [estimatedTimeClass, setEstimatedTimeClass] = useState("time_15_min"),
    [urgency, setUrgency] = useState(ASAP),
    [urgencyClass, setUrgencyClass] = useState("asap"),
    [urgencyIcon, setUrgencyIcon] = useState("heart-fill"),
    [taskStatusesNewData, setTaskStatusesNewData] = useState(taskStatusesData),
    [isShowEstimatedTime, setIsShowEstimatedTime] = useState(false),
    [isShowStatus, setIsShowStatus] = useState(false),
    [isShowUrgency, setIsShowUrgency] = useState(false),
    [isDisplayNewPerson, setIsDisplayNewPerson] = useState(false),
    [defaultSelectedRole, setDefaultSelectedRole] = useState(""),
    [defaultUserOptionSelected, setDefaultUserOptionSelected] = useState(ADD_NEW_CONTACT),
    [uploadedFile, setUploadedFile] = useState(null),
    [selectedPhoneType, setSelectedPhoneType] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),
    [selectedAddressType, setSelectedAddressType] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.type),
    [address, setAddress] = useState(""),
    [existingContactId, setExistingContactId] = useState("")

  const handleClose = () => {
    setShowModal(false)
  }

  const handleTransactionChange = event => {
    const selectedTransactionTypeId = event.target.value
    setDefaultProjectType(selectedTransactionTypeId)
    const selectedOptionDataType = event.target.selectedOptions[0].getAttribute("data-type")

    if (selectedOptionDataType == "event") {
      setIsSaveTask(false)
    } else {
      setIsSaveTask(true)
      getTransactionDetail(selectedTransactionTypeId)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const title = formData.get("title")
    const eventTime = formData.get("eventTime")

    if (title && eventTime) {
      if (isDisplayNewPerson && defaultUserOptionSelected === ADD_NEW_CONTACT) {
        if (formData.get("contactName") === "") {
          alert("Please enter Contact Name.")
          return false
        }
      }

      if (isDisplayNewPerson && defaultUserOptionSelected === SEARCH_EXISTING_CONTACTS) {
        if (existingContactId === "") {
          alert("Please select existing Contact.")
          return false
        }
      }

      return new Promise(async resolve => {
        var apiUrl = `/calendar/add`
        var postDataObj = {
          title,
          startTime: moment(eventTime).format(DATE_FORMAT),
          endTime: moment(eventTime).add(30, "minutes").format(DATE_FORMAT),
          eventType: "Service",
          metaJson: { event_note: "" },
        }

        if (isSaveTask) {
          const transactionType = parseInt(formData.get("scheduler_list"))

          apiUrl = `/task/add`
          postDataObj = {
            title,
            status: null,
            urgency,
            estimatedTime,
            note: "",
            transactionType: transactionType || undefined,
            transactionTypeStatus: transactionTypeStatus || null,
            startTime: moment(eventTime).format(DATE_FORMAT),
            endTime: moment(eventTime).add(30, "minutes").format(DATE_FORMAT),
          }
        }

        await AxiosInstance.post(apiUrl, postDataObj)
          .then(function (response) {
            if (response.status === 201) {
              if (isSaveTask) {
                const selectedId = response.data.task

                if (isDisplayNewPerson) {
                  let contactId
                  if (defaultUserOptionSelected === ADD_NEW_CONTACT) {
                    const contactData = {
                      name: formData.get("contactName"),
                      email: formData.get("contactEmail"),
                      profilePic: uploadedFile,
                      companyName: formData.get("companyName"),
                      jobTitle: formData.get("jobTitle"),
                      contactType: CONTACT_TYPE_HUMAN,
                      phone: {
                        type: selectedPhoneType,
                        phone: formData.get("contactPhone"),
                        permission: PRIVACY_PUBLIC,
                      },
                      address: {
                        type: selectedAddressType,
                        address: address,
                        permission: PRIVACY_PUBLIC,
                      },
                      metaData: [],
                      customFields: [],
                    }

                    AxiosInstance.post(`/contact/add`, contactData)
                      .then(contactResponse => {
                        contactId = contactResponse.data.contact

                        AxiosInstance.post(
                          `task/assign-task-contact/${selectedId}/${contactId}/${defaultSelectedRole}`
                        )
                      })
                      .catch(error => {
                        console.log(error)
                      })
                  } else {
                    contactId = existingContactId
                    AxiosInstance.post(
                      `task/assign-task-contact/${selectedId}/${contactId}/${defaultSelectedRole}`
                    )
                  }
                }
              }
              handleClose()
            }
            return response.data
          })
          .catch(function (error) {
            console.log(error)
          })
      })
    }
  }

  const projectTypesData = () => {
    AxiosInstance.get("/settings/transaction-type")
      .then(response => {
        setProjectTypes(response.data.items)
        const primaryType = response.data.items.filter(item => item.isPrimary)
        const defaultType = transactionTypeId
          ? transactionTypeId
          : primaryType.length > 0
          ? primaryType[0].id
          : ""

        if (defaultType) {
          setDefaultProjectType(defaultType)
          getTransactionDetail(defaultType)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getTransactionDetail = transactionTypeId => {
    return new Promise(async () => {
      await AxiosInstance.get(`/settings/transaction-type/detail/${transactionTypeId}`).then(function (
        response
      ) {
        if (response.status === 200) {
          const transactionType = response.data.type

          setTaskStatusesNewData(transactionType.statuses)
          setStatus(transactionType.statuses[0].label)
          setTransactionTypeStatus(transactionType.statuses[0].id)
          setStatusIcon(transactionType.statuses[0].icon)
          setIsSaveTask(true)
        }
      })
    })
  }

  const showHideTaskDropdown = (activeStatus, taskFor) => {
    if (taskFor == "status") {
      setIsShowStatus(activeStatus)
    } else if (taskFor == "urgency") {
      setIsShowUrgency(activeStatus)
    } else if (taskFor == "estimatedTime") {
      setIsShowEstimatedTime(activeStatus)
    }
  }

  useEffect(() => {
    projectTypesData()

    if (selectedContactId) {
      setDefaultUserOptionSelected(SEARCH_EXISTING_CONTACTS)
      setIsDisplayNewPerson(true)
    }
  }, [])

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      id="addNewTaskModal"
      dialogClassName={`modal-dialog-centered`}
    >
      <form onSubmit={handleSubmit} method="post">
        <Modal.Header>
          <h5 className="modal-title">
            <i className="bi bi-calendar3" /> Add Event
          </h5>{" "}
          &nbsp;
          <div className="addNewTaskHeaderType">
            <select
              className="form-select form-select-lg"
              name="scheduler_list"
              value={defaultProjectType || "Event"}
              id="scheduler_list"
              onChange={handleTransactionChange}
            >
              <optgroup label="== By Job / Project / Sales Opportunity ==" />

              {projectTypes.map((projectType, index) => (
                <option key={index} value={projectType.id}>
                  {projectType.dealTypeLabel}
                </option>
              ))}

              <optgroup label="== Other ==">
                <option value="Event" data-type="event">
                  Event
                </option>
              </optgroup>
            </select>
          </div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 row align-items-center">
            <label htmlFor="title" className="col-sm-4 col-form-label">
              Title
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                name="title"
                defaultValue={""}
                placeholder="Title"
              />
            </div>
          </div>
          <div className="row align-items-center">
            <label htmlFor="eventTime" className="col-sm-4 col-form-label">
              Event Time
            </label>
            <div className="col-sm-8">
              <input
                type="datetime-local"
                name="eventTime"
                id="eventTime"
                step="900"
                className="form-control"
                placeholder="Event date and time"
                defaultValue={selectedEventTime}
              />
            </div>
          </div>
          <br />

          {isSaveTask && (
            <div className="row">
              <div className="col-lg-12 addNewTaskBtnGroup">
                <div className="btn-group" role="group" id="statusContainer">
                  <button
                    type="button"
                    className={`btn dropdown-toggle statusContainer transactionTypeIconColor`}
                    data-bs-toggle={isShowStatus ? "dropdown" : ""}
                    aria-expanded="false"
                    onClick={() => showHideTaskDropdown(true, "status")}
                  >
                    <i className={`bi bi-${statusIcon}`} /> {status}
                  </button>
                  <ul className={`dropdown-menu hiddenStatus ${isShowStatus ? "show" : ""}`}>
                    {taskStatusesNewData.map((taskStatus, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setStatus(taskStatus.label)
                          setStatusIcon(taskStatus.icon)
                          setTransactionTypeStatus(taskStatus.id)
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => showHideTaskDropdown(false, "status")}
                        >
                          <span className="transactionTypeIconColor">
                            <i className={`bi bi-${taskStatus.icon}`} />
                          </span>{" "}
                          {taskStatus.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>{" "}
                <div className="btn-group" role="group" id="estimatedTimeContainer">
                  <button
                    type="button"
                    className={`btn dropdown-toggle estimatedTimeContainer estimated_${estimatedTimeClass}`}
                    data-bs-toggle={isShowEstimatedTime ? "dropdown" : ""}
                    aria-expanded="false"
                    onClick={() => showHideTaskDropdown(true, "estimatedTime")}
                  >
                    <i className="bi bi-clock" /> {estimatedTime}
                  </button>
                  <ul className={`dropdown-menu hiddenStatus ${isShowEstimatedTime ? "show" : ""}`}>
                    {taskTimeData.map((taskTime, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setEstimatedTime(taskTime.LABEL)
                          setEstimatedTimeClass(taskTime.CLASS)
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => showHideTaskDropdown(false, "estimatedTime")}
                        >
                          <span className={`estimated_${taskTime.CLASS}`}>
                            <i className="bi bi-clock" />
                          </span>{" "}
                          {taskTime.LABEL}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>{" "}
                <div className="btn-group" role="group" id="urgencyStatusContainer">
                  <button
                    type="button"
                    className={`btn dropdown-toggle urgencyStatusContainer urgencyStatus_${urgencyClass}`}
                    data-bs-toggle={isShowUrgency ? "dropdown" : ""}
                    aria-expanded="false"
                    onClick={() => showHideTaskDropdown(true, "urgency")}
                  >
                    <i className={`bi bi-${urgencyIcon}`} /> {urgency}
                  </button>

                  <ul className={`dropdown-menu hiddenStatus ${isShowUrgency ? "show" : ""}`}>
                    {taskUrgencyData.map((taskUrgency, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setUrgency(taskUrgency.LABEL)
                          setUrgencyClass(taskUrgency.CLASS)
                          setUrgencyIcon(taskUrgency.ICON)
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => showHideTaskDropdown(false, "urgency")}
                        >
                          <span className={`urgencyStatus_${taskUrgency.CLASS}`}>
                            <i className={`bi bi-${taskUrgency.ICON}`} />
                          </span>{" "}
                          {taskUrgency.LABEL}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>{" "}
                <div className="btn-group">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Add New Person / Role</Tooltip>}>
                    <button
                      type="button"
                      className="btnAddNewGreenDashed"
                      id="btnAddNewPersonRole"
                      onClick={() => {
                        setIsDisplayNewPerson(!isDisplayNewPerson)
                      }}
                    >
                      <i className="bi bi-person-fill" />
                    </button>
                  </OverlayTrigger>
                </div>{" "}
              </div>
            </div>
          )}

          {isDisplayNewPerson && (
            <TaskContactForm
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              defaultSelectedRole={defaultSelectedRole}
              setDefaultSelectedRole={setDefaultSelectedRole}
              setIsDisplayNewPerson={setIsDisplayNewPerson}
              setSelectedPhoneType={setSelectedPhoneType}
              setSelectedAddressType={setSelectedAddressType}
              existingContactId={existingContactId}
              setExistingContactId={setExistingContactId}
              defaultUserOptionSelected={defaultUserOptionSelected}
              setDefaultUserOptionSelected={setDefaultUserOptionSelected}
              address={address}
              setAddress={setAddress}
              selectedContactId={selectedContactId}
              selectedContactName={selectedContactName}
              selectedContactImage={selectedContactImage}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-default" onClick={handleClose}>
            <i className="bi bi-x-lg" /> Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            <i className="bi bi-check-lg" /> Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
export default EventModal
