import * as React from "react"
import { useEffect, useState } from "react"
import _uniqueId from "lodash/uniqueId"
import { Form } from "react-bootstrap"
import Slider from "rc-slider"
import { PRIVACY_PRIVATE, PRIVACY_PUBLIC } from "../../../../services/globalStaticData"
import AxiosInstance from "../../../../services/axiosInstance"

const CustomFieldSlider = ({ contactId, customField, getContactData, setCustomFields }) => {
  const [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(parseInt(customField.data)),
    [oldInfoData, setOldInfoData] = useState(parseInt(customField.data)),
    [dataPrivacy, setDataPrivacy] = useState(customField.permission),
    [oldDataPrivacy, setOldDataPrivacy] = useState(customField.permission),
    [showRatting, setShowRatting] = useState(true)

  let customFieldName = "contact_custom_fields_slider_" + _uniqueId()
  let privacyIcon = customField.settingCustomField.icon
  let privacyLabel = "Public"

  if (dataPrivacy === PRIVACY_PRIVATE) {
    privacyIcon = customField.settingCustomField.icon + " privateInfo"
    privacyLabel = "Private"
  }

  const updatePrivacyInfo = () => {
    setDataPrivacy(dataPrivacy === PRIVACY_PRIVATE ? PRIVACY_PUBLIC : PRIVACY_PRIVATE)
    showSaveRowOptions()
  }

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setShowRatting(false)
    setInfoData(oldInfoData)
    setDataPrivacy(oldDataPrivacy)
    setSaveOption(false)
  }

  const updateRowData = () => {
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/custom-field/edit/${customField.id}`, {
        type: customField.type,
        permission: dataPrivacy,
        value: infoData,
        settingCustomFieldId: customField.settingCustomField.id,
      })
        .then(function (response) {
          if (response.status === 200) {
            setOldDataPrivacy(dataPrivacy)
            setOldInfoData(infoData)
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  const deleteCustomField = customFieldId => {
    if (customFieldId) {
      return new Promise(async () => {
        await AxiosInstance.delete(`/contact/custom-field/delete/${customFieldId}`)
          .then(function (response) {
            if (response.status === 200) {
              setUpdateContact(true)
            }
          })
          .catch(function (error) {
            alert("there is some error please try again after some time.")
          })
      })
    }
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      setCustomFields([])
      getContactData(contactId)
      hideSaveRowOptions()
    }

    if (showRatting === false) {
      setShowRatting(true)
    }
  })

  return (
    <React.Fragment>
      <div className="CD_Info_Row border-bottom p-3">
        <div className="row align-items-center">
          <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">
            {customField.settingCustomField.label}
          </div>

          <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
            <a
              data-bs-toggle="tooltip"
              data-placement="top"
              title={`${privacyLabel} Information`}
              onClick={updatePrivacyInfo}
            >
              <i className={privacyIcon} />
            </a>
          </div>
          <div className="col-sm-6 col-xs-12">
            {showRatting && (
              <>
                <Form.Control
                  value={infoData}
                  name={customFieldName}
                  type="number"
                  id={`CD_rave_score_input_${_uniqueId()}`}
                  className="d-none"
                  data-slider-min="0"
                  data-slider-max="100"
                  data-slider-value="50"
                  onChange={() => {}}
                />

                <div className="CD_Rave_score">{infoData}</div>
                <Slider
                  defaultValue={infoData}
                  onChange={value => {
                    setInfoData(value)
                    showSaveRowOptions()
                  }}
                />
              </>
            )}
          </div>
          <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
            {saveOption && (
              <>
                <a
                  className="btnCDEdit"
                  data-bs-toggle="tooltip"
                  data-placement="top"
                  aria-label="Save"
                  onClick={() => {
                    updateRowData()
                  }}
                >
                  <i className="bi bi-save2" />
                </a>

                <a
                  className="btnCDDelete"
                  data-bs-toggle="tooltip"
                  data-placement="top"
                  title="Cancel"
                  onClick={() => hideSaveRowOptions()}
                >
                  <i className="bi bi-x-lg" />
                </a>
              </>
            )}

            {!saveOption && (
              <a
                className="btnCDDelete"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => deleteCustomField(customField.id)}
              >
                <i className="bi bi-trash-fill" />
              </a>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CustomFieldSlider
