import React, { useState } from "react"
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CONTACT_TYPE_HUMAN } from "../contactHelper"
import AxiosInstance from "../../../services/axiosInstance"
import { Link } from "gatsby"
import AddSubContact from "./addSubContact"

const SubContact = ({
  contactType,
  contactId,
  subContacts,
  setSubContactData,
  selectedContactId,
  setContactType,
}) => {
  const [modalShow, setModalShow] = useState(false)
  const [linkModal, setLinkModal] = useState(false)

  const handleModalShow = () => {
    setModalShow(true)
  }
  const handleLinkModalShow = () => {
    setLinkModal(true)
  }

  const deleteSubContact = (subContactId, data) => {
    return new Promise(async resolve => {
      let success = false
      await AxiosInstance.delete(`/contact/delete/${subContactId}`).then(response => {
        if (response.data) {
          success = response.data.success
          const removeSubContact = subContacts.filter(subId => {
            return subId.id !== subContactId
          })
          setSubContactData(removeSubContact)
        }
      })

      resolve(data, success)
    })
  }

  return (
    <div className="CD_Info_Row border-bottom p-3 CD_Info_Last_Row contactAtCompanyLinkedRow">
      <div className="row">
        <div className="col-sm-12 text-start">
          <ul className="user-card-list">
            {subContacts.map(subcontact => {
              return (
                <li className="contactAtCompanyLinked" key={subcontact.id}>
                  <div>
                    <img
                      src={`${process.env.GATSBY_WRANGLER_URL}${
                        subcontact.profilePic ? subcontact.profilePic : "StaticImages/avatar.gif"
                      }`}
                      alt="#"
                      className="user_avatar"
                    />
                    <a href={`/contacts/detail/${subcontact.id}`} className="subcontactName">
                      {subcontact.name}
                    </a>{" "}
                    {`(${
                      subcontact.contactType === CONTACT_TYPE_HUMAN ? subcontact.jobRole : subcontact.jobTitle
                    })`}
                    <div className="contactListQuickTools">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{subcontact?.emails[0]?.email}</Tooltip>}
                      >
                        <Link
                          to="#"
                          className="btnOpenChatDockEmail"
                          data-value="email"
                          data-bs-toggle="tooltip"
                        >
                          <i className="bi bi-envelope-fill" />
                        </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{`SMS to ${subcontact?.phones[0]?.phone}`}</Tooltip>}
                      >
                        <Link to="#" className="btnOpenChatDockSMS">
                          <i className="bi bi-phone-fill" />
                        </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{`Call to ${subcontact.phones[0]?.phone}`}</Tooltip>}
                      >
                        <Link to="#" className="btnOpenChatDockCall">
                          <i className="bi bi-telephone-fill" />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="statusActionBox">
                      <Link
                        to={`/contacts/detail/${subcontact.id}`}
                        className="btn btn-default btm-sm btnEdit"
                      >
                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                          <span>
                            <i className="bi bi-pencil-fill" />
                          </span>
                        </OverlayTrigger>
                      </Link>
                      <a
                        href="#"
                        className="btn btn-default btm-sm btnDelete"
                        onClick={() => deleteSubContact(subcontact.id)}
                      >
                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                          <span>
                            <i className="bi bi-trash-fill" />
                          </span>
                        </OverlayTrigger>
                      </a>
                    </div>
                  </div>
                </li>
              )
            })}

            <li className="contactAtCompanyLinked btnAddNewContactAtCompanyLinkedContainer">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="btnAddNewGreenDashed btnAddNewContactAtCompanyLinked show no-dropdown-arrow"
                  type="button"
                >
                  <OverlayTrigger placement="top" overlay={<Tooltip>Add New</Tooltip>}>
                    <span>
                      <i className="bi bi-person-fill-add" />
                    </span>
                  </OverlayTrigger>
                </Dropdown.Toggle>
                <Dropdown.Menu data-popper-placement="bottom-start">
                  <Dropdown.Item href="#" onClick={handleModalShow}>
                    <i className="bi bi-person-fill-add" />{" "}
                    {contactType === CONTACT_TYPE_HUMAN ? "Add Related Contact" : "Add Company Contact"}
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={handleLinkModalShow}>
                    <i className="bi bi-link-45deg" />{" "}
                    {contactType === CONTACT_TYPE_HUMAN ? "Link Related Contact" : "Link Contact to Company"}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>

      <AddSubContact
        contactType={contactType}
        contactId={contactId}
        subContacts={subContacts}
        setSubContactData={setSubContactData}
        selectedContactId={selectedContactId}
        setContactType={setContactType}
        setModalShow={setModalShow}
        setLinkModal={setLinkModal}
        modalShow={modalShow}
        linkModal={linkModal}
      />
    </div>
  )
}

export default SubContact
