import * as React from "react"
import "./accounting.css"
const AccountingPanel = () => {
  return (
    <div className="col-lg-12 mb-3" id="accounting_panel">
      <div className="accordion-item panel panel-blue">
        <div
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#account_container"
          aria-expanded="false"
          aria-controls="account_container"
        >
          <i className="bi bi-card-list me-2" /> Accounting
        </div>
        <div
          id="account_container"
          className="accordion-collapse collapse"
          aria-labelledby="account_container"
        >
          <div className="accordion-body">
            <div className="row tabularMenuContainer">
              <div className="col-sm-4">
                <select className="form-select">
                  <option>Primary Ledger</option>
                  <option>Points Pseudocurrency</option>
                  <option>OtherUserLedger3</option>
                </select>
              </div>
              <div className="col-sm-8">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                    <li className="nav-item">
                      <div className="btn-group btnAddNewDropdown" role="group">
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-plus-lg" /> <span className="d-none d-md-inline">Add New</span>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#add_new_transaction"
                            >
                              <i className="bi bi-currency-rupee" /> ManualRecord
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-list-ul" /> Create Invoice
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item btnAddNewSubscription" href="#">
                              <i className="bi bi-repeat" /> Agreement
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="history-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#history"
                        role="tab"
                        aria-controls="history"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="History / Paid">
                          <i className="bi bi-arrow-counterclockwise" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="projected-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#projected"
                        role="tab"
                        aria-controls="projected"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Projected / Upcoming">
                          <i className="bi bi-arrow-clockwise" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="agree-subscription-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#agree-subscription"
                        role="tab"
                        aria-controls="agree-subscription"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Agreements & Subscriptions">
                          <i className="bi bi-repeat" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <a className="nav-link" href="#" data-bs-toggle="tooltip" data-bs-title="Trash">
                        <i className="bi bi-trash-fill" />
                      </a>
                    </li>
                  </ul>

                  {/* mobile view */}
                  <ul className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1" role="tablist">
                    <li className="nav-item">
                      <div className="btn-group btnAddNewDropdown" role="group">
                        <button
                          id="btnGroupDrop2"
                          type="button"
                          className="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-plus-lg" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop2">
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#add_new_transaction"
                            >
                              <i className="bi bi-currency-rupee" /> Manual Record
                            </a>
                            {/*SHOW USER'S CURRENCY SYMBOL HERE */}
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-list-ul" /> Create Invoice
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item btnAddNewSubscription" href="#">
                              <i className="bi bi-repeat" /> Agreement
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="btn-group btnDriveView" role="group">
                        <button
                          id="btnGroupDrop3"
                          type="button"
                          className="btn btn-default dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-database-fill" />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item active"
                              id="history-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#history"
                              role="tab"
                              aria-controls="history"
                              aria-selected="false"
                            >
                              <i className="bi bi-arrow-counterclockwise" /> History / Paid
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              id="projected-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#projected"
                              role="tab"
                              aria-controls="projected"
                              aria-selected="false"
                            >
                              <i className="bi bi-arrow-clockwise" /> Projected / Upcoming
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              id="agree-subscription-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#agree-subscription"
                              role="tab"
                              aria-controls="agree-subscription"
                              aria-selected="false"
                            >
                              <i className="bi bi-repeat" /> Agreements & Subscriptions
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-trash-fill" /> Trash
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="history"
                    role="tabpanel"
                    aria-labelledby="history-tab"
                  >
                    <div className="table-responsive">
                      <table id="tblAccountHistory" className="table dataTable" cellSpacing="0" width="100%">
                        <thead>
                          <tr>
                            <th width="5%">
                              <a tabIndex="0" href="#" className="history_table_action table-head-icon">
                                <i className="bi bi-gear-fill" />
                              </a>
                            </th>
                            <th width="5%" className="d-none d-md-table-cell">
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="10%" className="d-none d-md-table-cell text-center">
                              Date{" "}
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="40%">
                              Type / Title / Memo{" "}
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="10%" className="text-end">
                              Amount{" "}
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="10%" className="d-none d-md-none display-none balance-col">
                              Balance
                            </th>
                            <th width="20%" className="d-none d-md-none d-lg-table-cell" />
                          </tr>
                          <tr className="display-none history_table_action_container">
                            <td colSpan="7" className="text-left">
                              <div className="batch-action-container">
                                <button className="btn btn-trash">
                                  <i className="bi bi-trash-fill" /> Move to Trash
                                </button>
                                <button
                                  className="btn medium_purple"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Merge Duplicate Records"
                                >
                                  <i className="bi bi-share-fill flip-horizontal" /> Merge
                                </button>
                                <button
                                  className="btn btn-success"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Nest related records into a single row to keep things organized."
                                >
                                  <i className="bi bi-bezier" /> Nest
                                </button>
                                <button
                                  className="btn btn-default"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Un-Nest"
                                >
                                  <i className="bi bi-bezier2" /> Un-Nest
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Tags"
                                >
                                  <i className="bi bi-tags-fill" /> Tags
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr className="display-none history_table_action_container history_filter_table_action_container">
                            <td colSpan="7" className="text-left">
                              <div className="batch-action-container">
                                <div className="row no-gutters">
                                  <div className="col-lg-12">
                                    <div className="form-inline mb-3">
                                      <label className="mb-0 mr-2">By Type</label>
                                      <select className="form-select mb-0">
                                        <option value="Image">Image</option>
                                        <option value="Audio">Audio</option>
                                        <option value="Video">Video</option>
                                        <option value="Archive">Archive</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row no-gutters">
                                  <div className="col-lg-8 col-md-8 col-sm-12">
                                    <div className="form-inline mb-2">
                                      <h4>
                                        <i className="bi bi-funnel-fill" /> Show me
                                        <select className="form-select mb-0 mx-2">
                                          <option value="Contacts">Contacts</option>
                                          <option value="Files_Workrooms">Files & Workrooms</option>
                                          <option value="Accounting_Records">Accounting Records</option>
                                        </select>
                                        that are…
                                      </h4>
                                    </div>
                                    <div className="mb-3">
                                      <select className="form-select mb-3">
                                        <option value="Tagged_with…">Tagged with…</option>
                                        <option value="Selling_Opportunities">Selling Opportunities</option>
                                      </select>
                                      <button
                                        type="button"
                                        className="btnAddNew"
                                        id="btnAddNew"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Add New"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label>& Add a Custom Column</label>
                                      <input
                                        type="text"
                                        name="addCustomColumn"
                                        placeholder="tag-score"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label>& then sort the records by…</label>
                                      <input
                                        type="text"
                                        name="sortRecordsBy"
                                        placeholder="tag-score"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-1" />
                                  <div className="col-lg-3 col-md-3 col-sm-12 savedFilterdView">
                                    <h4>
                                      <i className="bi bi-bullseye" /> Saved Filtered Views
                                    </h4>
                                    <ul>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          Helpdesk Tasks
                                        </a>
                                      </li>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          Homebuyer Selling Opps
                                        </a>
                                      </li>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          Open Client Projects
                                        </a>
                                      </li>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          UserSavedFilterLabelHere
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID12" className="css-checkbox" />
                                <label htmlFor="H_ID12" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_green">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Projected"
                              >
                                <i className="bi bi-clock" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">06/08/2017</td>
                            <td className="col_to_from">
                              Invoice #002 from
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search" data-val="Tejas">
                                    <img src="images/tejas.png" alt="" className="user_avatar" /> Tejas H.
                                  </a>
                                </li>
                              </ul>
                              for week ending 12 June
                            </td>
                            <td className="text-end">
                              <button
                                className="btn btn-pay"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Click to Pay Now"
                              >
                                <strong>
                                  <i className="bi bi-box-arrow-right" /> 75.00
                                </strong>
                              </button>
                            </td>
                            <td className="text-center d-none d-md-none display-none balance-col balance-color-red" />
                            <td className="text-end colAction d-none d-md-none d-lg-table-cell">
                              <a
                                href="#"
                                className="btn btn-colAction btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-colAction btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="nested_row neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID1" className="css-checkbox" />
                                <label htmlFor="H_ID1" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_yellow">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <a
                                href="#"
                                data-type="invoice"
                                className="expand_child_row"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Expand Detailed View"
                              >
                                <i className="bi bi bi-plus-circle-fill" />
                              </a>
                            </td>
                            <td className="text-center d-none d-md-table-cell">02/13/2017</td>
                            <td className="col_to_from">
                              Payment From
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search" data-val="Rosa">
                                    <img src="images/Rosa.jpg" alt="" className="user_avatar" /> Rosa
                                  </a>
                                </li>
                              </ul>
                              for SSL
                            </td>
                            <td className="text-end">200.00</td>
                            <td className="text-center d-none d-md-none display-none balance-col balance-color-red">
                              (-200.00)
                            </td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-colAction btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-colAction btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID2" className="css-checkbox" />
                                <label htmlFor="H_ID2" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_blue">
                                  <i className="bi bi-check2" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-paypal" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">01/20/2017</td>
                            <td className="col_to_from">
                              Payment From
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search" data-val="Rosa">
                                    <img src="images/susan.jpg" alt="" className="user_avatar" /> Susan
                                  </a>
                                </li>
                              </ul>
                              for Hosting through...
                              <div className="SwiftCloudTableTags">
                                <ul>
                                  <li>
                                    <a href="javaScript:void(0);" data-tagname="hosting">
                                      hosting
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            <td className="text-end">291.30</td>
                            <td className="text-center d-none d-md-none display-none balance-col">291.30</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-colAction btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID3" className="css-checkbox" />
                                <label htmlFor="H_ID3" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_red">
                                  <i className="bi bi-clock" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Chase Business"
                              >
                                <i className="bi bi-bank2" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">06/02/2016</td>
                            <td>
                              Payment To Bank Wire
                              <div className="SwiftCloudTableTags">
                                <ul>
                                  <li>
                                    <a href="javaScript:void(0);" data-tagname="06/12/2022">
                                      06/12/2022
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            <td className="text-end">
                              <i>(-123.00)</i>
                            </td>
                            <td className="text-center d-none d-md-none display-none balance-col">
                              (-123.00)
                            </td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-colAction btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID4" className="css-checkbox" />
                                <label htmlFor="H_ID4" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_red">
                                  <i className="bi bi-check2" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-paypal" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">06/24/2015</td>
                            <td>Payment To Tonys lidia showbiz ded</td>
                            <td className="text-end">(-39.21)</td>
                            <td className="text-center d-none d-md-none display-none balance-col">
                              (-39.21)
                            </td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-colAction btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-colAction btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID5" className="css-checkbox" />
                                <label htmlFor="H_ID5" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_green">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <a
                                href="#"
                                className="expand_child_row"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Expand Detailed View"
                              >
                                <i className="bi bi bi-plus-circle-fill" />
                              </a>
                            </td>
                            <td className="text-center d-none d-md-table-cell">08/05/2016</td>
                            <td>Balance Transfer for Xoom bonus coverage</td>
                            <td className="text-end">
                              <i className="bi bi-arrow-left-right" /> 50.00
                            </td>
                            <td className="text-center d-none d-md-none display-none balance-col"> 50.00</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID6" className="css-checkbox" />
                                <label htmlFor="H_ID6" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_blue">
                                  <i className="bi bi-check2" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Cash"
                              >
                                <i className="bi bi-currency-dollar" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">04/27/2015</td>
                            <td className="col_to_from">
                              Payment To
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search" data-val="CheapSSL.com">
                                    <img src="images/pedro.jpg" alt="" className="user_avatar" /> CheapSSL.com
                                  </a>
                                </li>
                              </ul>
                              for Test - running balance
                            </td>
                            <td className="text-end">(-10.50)</td>
                            <td className="text-center d-none d-md-none display-none balance-col">
                              (-10.50)
                            </td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="nested_row neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID11" className="css-checkbox" />
                                <label htmlFor="H_ID11" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_green">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <a
                                href="#"
                                data-type="negative"
                                className="expand_child_row"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Expand Detailed View"
                              >
                                <i className="bi bi bi-plus-circle-fill" />
                              </a>
                            </td>
                            <td className="text-center d-none d-md-table-cell">08/05/2016</td>
                            <td className="col_to_from">
                              Invoice #001 from
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search" data-val="Tejas">
                                    <img src="images/tejas.png" alt="" className="user_avatar" /> Tejas H.
                                  </a>
                                </li>
                              </ul>
                              for Week ending 5 June
                            </td>
                            <td className="text-end amount_col" data-amt="-100.00">
                              (-100.00)
                            </td>
                            <td className="text-center d-none d-md-none display-none balance-col">
                              (-100.00)
                            </td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="nested_row neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID7" className="css-checkbox" />
                                <label htmlFor="H_ID7" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_green">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <a
                                href="#"
                                data-type="invoice"
                                className="expand_child_row"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Expand Detailed View"
                              >
                                <i className="bi bi bi-plus-circle-fill" />
                              </a>
                            </td>
                            <td className="text-center d-none d-md-table-cell">01/31/2017</td>
                            <td className="col_to_from">
                              Payment from
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search" data-val="Bruce Mack">
                                    <img src="images/aHNPPA8z.jpg" alt="" className="user_avatar" /> Bruce
                                    Mack
                                  </a>
                                </li>
                              </ul>
                              for Week ending 5 June
                            </td>
                            <td className="text-end">100.00</td>
                            <td className="text-center d-none d-md-none display-none balance-col">100.00</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>

                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID9" className="css-checkbox" />
                                <label htmlFor="H_ID9" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_green">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-paypal" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">04/08/2017</td>
                            <td className="col_to_from">
                              Payment To
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search" data-val="Samier Sompura">
                                    <img src="images/avatar.gif" alt="" className="user_avatar" /> Samier
                                    Sompura
                                  </a>
                                </li>
                              </ul>
                              for Test
                            </td>
                            <td className="text-end">(-100.00)</td>
                            <td className="text-center d-none d-md-none display-none balance-col">
                              (-100.00)
                            </td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="balance_transfer_row neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="H_ID10" className="css-checkbox" />
                                <label htmlFor="H_ID10" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_red">
                                  <i className="bi bi-x-circle" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-paypal" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">07/25/2016</td>
                            <td className="col_to_from">
                              Canceled by
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search" data-val="Samier Sompura">
                                    <img src="images/avatar.gif" alt="" className="user_avatar" /> Samier
                                    Sompura
                                  </a>
                                </li>
                              </ul>
                              for Canceled Test
                            </td>
                            <td className="text-end">
                              <i>100.00</i>
                            </td>
                            <td className="text-center d-none d-md-none display-none balance-col">100.00</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className="accouting_total_col">
                            <th colSpan="4" style={{ textAlign: "right" }}>
                              Total:
                            </th>
                            <th colSpan="3" className="d-none d-md-block d-lg-table-cell">
                              &nbsp;
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="projected"
                    role="tabpanel"
                    aria-labelledby="projected-tab"
                  >
                    <div className="table-responsive">
                      <table
                        id="projected_table"
                        className="table table-striped customCheckbox table-head-color dataTable no-footer"
                        cellSpacing="0"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th width="5%">
                              <a tabIndex="0" href="#" className="projected_table_action table-head-icon">
                                <i className="bi bi-gear-fill" />
                              </a>
                            </th>
                            <th width="5%" className="d-none d-md-table-cell text-center">
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="10%" className="d-none d-md-table-cell text-center">
                              Date{" "}
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="40%">
                              Type / Title / Memo{" "}
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="15%" className="text-end">
                              Amount{" "}
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="25%" className="d-none d-md-none d-lg-table-cell" />
                          </tr>
                          <tr className="display-none projected_table_action_container">
                            <td colSpan="6" className="text-left">
                              <div className="batch-action-container">
                                <button className="btn btn-trash">
                                  <i className="bi bi-trash-fill" /> Move to Trash
                                </button>
                                <button
                                  className="btn medium_purple"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Merge Duplicate Records"
                                >
                                  <i className="bi bi-share-fill flip-horizontal" /> Merge
                                </button>
                                <button
                                  className="btn btn-success"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Nest related records into a single row to keep things organized."
                                >
                                  <i className="bi bi-bezier" /> Nest
                                </button>
                                <button
                                  className="btn btn-default"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Un-Nest"
                                >
                                  <i className="bi bi-bezier2" /> Un-Nest
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Tags"
                                >
                                  <i className="bi bi-tags-fill" /> Tags
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr className="display-none projected_table_action_container filter_table_action_container">
                            <td colSpan="6" className="text-left">
                              <div className="batch-action-container">
                                <div className="row no-gutters">
                                  <div className="col-lg-12">
                                    <div className="form-inline mb-3">
                                      <label className="mb-0 mr-2">By Type</label>
                                      <select className="form-select mb-0">
                                        <option value="Image">Image</option>
                                        <option value="Audio">Audio</option>
                                        <option value="Video">Video</option>
                                        <option value="Archive">Archive</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row no-gutters">
                                  <div className="col-lg-9 col-md-9 col-sm-12">
                                    <div className="form-inline mb-2">
                                      <h4>
                                        <i className="bi bi-funnel-fill" /> Show me
                                        <select className="form-select mb-0 mx-2">
                                          <option value="Contacts">Contacts</option>
                                          <option value="Files_Workrooms">Files & Workrooms</option>
                                          <option value="Accounting_Records">Accounting Records</option>
                                        </select>
                                        that are…
                                      </h4>
                                    </div>
                                    <div className="mb-3">
                                      <select className="form-select mb-3">
                                        <option value="Tagged_with…">Tagged with…</option>
                                        <option value="Selling_Opportunities">Selling Opportunities</option>
                                      </select>
                                      <button
                                        type="button"
                                        className="btnAddNew"
                                        id="btnAddNew"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Add New"
                                      ></button>
                                    </div>
                                    <div className="mb-3">
                                      <label>& Add a Custom Column</label>
                                      <input
                                        type="text"
                                        name="addCustomColumn"
                                        placeholder="tag-score"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label>& then sort the records by…</label>
                                      <input
                                        type="text"
                                        name="sortRecordsBy"
                                        placeholder="tag-score"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-12 savedFilterdView">
                                    <h4>
                                      <i className="bi bi-bullseye" /> Saved Filtered Views
                                    </h4>
                                    <ul>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          Helpdesk Tasks
                                        </a>
                                      </li>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          Homebuyer Selling Opps
                                        </a>
                                      </li>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          Open Client Projects
                                        </a>
                                      </li>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          UserSavedFilterLabelHere
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="P_ID12" className="css-checkbox" />
                                <label htmlFor="P_ID12" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_green">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Projected"
                              >
                                <i className="bi bi-clock" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">06/08/2017</td>
                            <td className="col_to_from">
                              Invoice #002 from
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search" data-val="Tejas">
                                    <img src="images/tejas.png" alt="" className="user_avatar" /> Tejas H.
                                  </a>
                                </li>
                              </ul>
                              for week ending 12 June
                            </td>
                            <td className="text-end">
                              <button
                                className="btn btn-pay"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Click to Pay Now"
                              >
                                <strong>
                                  <i className="bi bi-box-arrow-right" /> 75.00
                                </strong>
                              </button>
                            </td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-colAction btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-colAction btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="P_ID1" className="css-checkbox" />
                                <label htmlFor="P_ID1" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_blue">
                                  <i className="bi bi-arrow-clockwise" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-paypal" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">02/13/2017</td>
                            <td className="col_to_from">
                              Subscription Payment From
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search">
                                    <img src="images/Rosa.jpg" alt="" className="user_avatar" /> Rosa
                                  </a>
                                </li>
                              </ul>
                              for SSL
                            </td>
                            <td className="text-end">194.30</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-colAction btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-colAction btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="P_ID2" className="css-checkbox" />
                                <label htmlFor="P_ID2" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_green">
                                  <i className="bi bi-box-arrow-in-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-file-earmark-text" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">01/20/2017</td>
                            <td className="col_to_from">
                              Invoice To
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search">
                                    <img src="images/susan.jpg" alt="" className="user_avatar" /> Susan
                                  </a>
                                </li>
                              </ul>
                              for Hosting through...{" "}
                              <div className="colTags">
                                <a href="#" data-val="hosting">
                                  #hosting
                                </a>
                              </div>
                            </td>
                            <td className="text-end">291.30</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-colAction btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="P_ID3" className="css-checkbox" />
                                <label htmlFor="P_ID3" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_red">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Chase Business"
                              >
                                <i className="bi bi-bank2" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">06/02/2016</td>
                            <td>
                              Payment To Bank Wire{" "}
                              <div className="colTags">
                                <a href="#" data-val="06/02/2016">
                                  #06/02/2016
                                </a>
                              </div>
                            </td>
                            <td className="text-end">(-123.00)</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-colAction btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="P_ID4" className="css-checkbox" />
                                <label htmlFor="P_ID4" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_red">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-paypal" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">06/24/2015</td>
                            <td>Payment To Tonys lidia showbiz ded</td>
                            <td className="text-end">(-39.21)</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-colAction btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-colAction btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="P_ID5" className="css-checkbox" />
                                <label htmlFor="P_ID5" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_blue">
                                  <i className="bi bi-arrow-clockwise" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Cash"
                              >
                                <i className="bi bi-currency-dollar" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">04/27/2015</td>
                            <td className="col_to_from">
                              Subscription Payment To
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search">
                                    <img src="images/pedro.jpg" alt="" className="user_avatar" /> CheapSSL.com
                                  </a>
                                </li>
                              </ul>
                              for Test - running balance
                            </td>
                            <td className="text-end">(-10.50)</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="P_ID6" className="css-checkbox" />
                                <label htmlFor="P_ID6" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_red">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-paypal" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">01/31/2017</td>
                            <td className="col_to_from">
                              Payment To
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search">
                                    <img src="images/tejas.png" alt="" className="user_avatar" /> Tejas H.
                                  </a>
                                </li>
                              </ul>
                              for Test
                            </td>
                            <td className="text-end">(-100.00)</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="P_ID7" className="css-checkbox" />
                                <label htmlFor="P_ID7" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_green">
                                  <i className="bi bi-box-arrow-in-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-file-earmark-text" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">05/27/2017</td>
                            <td className="col_to_from">
                              Invoice To
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search">
                                    <img src="images/tejas.png" alt="" className="user_avatar" /> Tejas H.
                                  </a>
                                </li>
                              </ul>
                              for Test
                            </td>
                            <td className="text-end">100.00</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="P_ID8" className="css-checkbox" />
                                <label htmlFor="P_ID8" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status bg_red">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">
                              <span
                                className="btn btn-status"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="PayPal Main"
                              >
                                <i className="bi bi-paypal" />
                              </span>
                            </td>
                            <td className="text-center d-none d-md-table-cell">04/08/2017</td>
                            <td className="col_to_from">
                              Payment To{" "}
                              <a href="#" className="user_search" data-val="Samier Sompura">
                                Samier Sompura
                              </a>{" "}
                              <a
                                href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                                className="user_link"
                                target="_blank"
                              >
                                <i className="bi bi-person-fill" />
                              </a>{" "}
                              for Test
                            </td>
                            <td className="text-end">(-100.00)</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">1</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="agree-subscription"
                    role="tabpanel"
                    aria-labelledby="agree-subscription-tab"
                  >
                    <div className="table-responsive">
                      <table
                        id="agreements_subscriptions_table"
                        className="table table-striped customCheckbox table-head-color dataTable no-footer"
                        cellSpacing="0"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th width="5%">
                              <a
                                tabIndex="0"
                                href="#"
                                className="agreements_subscriptions_table_action table-head-icon"
                              >
                                <i className="bi bi-gear-fill" />
                              </a>
                            </th>
                            <th width="10%" className="d-none d-md-table-cell text-center">
                              Renewal Date
                            </th>
                            <th width="40%">
                              Product{" "}
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="15%" className="d-none d-md-table-cell">
                              Type{" "}
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="10%" className="text-end">
                              Amount{" "}
                              <a
                                href="#"
                                className="table_filter"
                                onClick="event.stopPropagation();"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Custom Column View"
                              >
                                <i className="bi bi-plus-lg-square" />
                              </a>
                            </th>
                            <th width="20%" className="d-none d-md-none d-lg-table-cell"></th>
                          </tr>
                          <tr className="display-none agreements_subscriptions_table_action_container">
                            <td colSpan="6" className="text-left">
                              <div className="batch-action-container">
                                <button className="btn btn-trash">
                                  <i className="bi bi-trash-fill" /> Move to Trash
                                </button>
                                <button
                                  className="btn medium_purple"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Merge Duplicate Records"
                                >
                                  <i className="bi bi-share-fill flip-horizontal" /> Merge
                                </button>
                                <button
                                  className="btn btn-success"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Nest related records into a single row to keep things organized."
                                >
                                  <i className="bi bi-bezier" /> Nest
                                </button>
                                <button
                                  className="btn btn-default"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Un-Nest"
                                >
                                  <i className="bi bi-bezier2" /> Un-Nest
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title="Tags"
                                >
                                  <i className="bi bi-tags-fill" /> Tags
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr className="display-none agreements_subscriptions_table_action_container filter_table_action_container">
                            <td colSpan="6" className="text-left">
                              <div className="batch-action-container">
                                <div className="row no-gutters">
                                  <div className="col-lg-12">
                                    <div className="form-inline mb-3">
                                      <label className="mb-0 mr-2">By Type</label>
                                      <select className="form-select mb-0">
                                        <option value="Image">Image</option>
                                        <option value="Audio">Audio</option>
                                        <option value="Video">Video</option>
                                        <option value="Archive">Archive</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row no-gutters">
                                  <div className="col-lg-8 col-sm-12">
                                    <div className="form-inline mb-2">
                                      <h4>
                                        <i className="bi bi-funnel-fill" /> Show me
                                        <select className="form-select mb-0 mx-2">
                                          <option value="Contacts">Contacts</option>
                                          <option value="Files_Workrooms">Files & Workrooms</option>
                                          <option value="Accounting_Records">Accounting Records</option>
                                        </select>
                                        that are…
                                      </h4>
                                    </div>
                                    <div className="mb-3">
                                      <select className="form-select mb-3">
                                        <option value="Tagged_with…">Tagged with…</option>
                                        <option value="Selling_Opportunities">Selling Opportunities</option>
                                      </select>
                                      <button
                                        type="button"
                                        className="btnAddNew"
                                        id="btnAddNew"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Add New"
                                      ></button>
                                    </div>
                                    <div className="mb-3">
                                      <label>& Add a Custom Column</label>
                                      <input
                                        type="text"
                                        name="addCustomColumn"
                                        placeholder="tag-score"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label>& then sort the records by…</label>
                                      <input
                                        type="text"
                                        name="sortRecordsBy"
                                        placeholder="tag-score"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-1"></div>
                                  <div className="col-lg-3 col-md-3 col-sm-12 savedFilterdView">
                                    <h4>
                                      <i className="bi bi-bullseye" /> Saved Filtered Views
                                    </h4>
                                    <ul>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          Helpdesk Tasks
                                        </a>
                                      </li>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          Homebuyer Selling Opps
                                        </a>
                                      </li>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          Open Client Projects
                                        </a>
                                      </li>
                                      <li>
                                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                                          UserSavedFilterLabelHere
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="neutral_row">
                            <td className="text-center tableColChkDel">
                              <div className="tableColChkDelBox">
                                <input type="checkbox" name="fileid[]" id="AS_ID1" className="css-checkbox" />
                                <label htmlFor="AS_ID1" className="css-checkbox-label" />
                                <span className="tableColChkSpan account_status">
                                  <i className="bi bi-box-arrow-right" />
                                </span>
                              </div>
                            </td>
                            <td className="text-center d-none d-md-table-cell">06/08/2017</td>
                            <td>
                              Electronic Signature Business Package - Monthly From
                              <ul className="user-card-list">
                                <li>
                                  <a href="#" className="user_search">
                                    <img
                                      src="images/bachchan-amitabh-image.jpg"
                                      alt=""
                                      className="user_avatar"
                                    />{" "}
                                    Amitabh Bachchan
                                  </a>
                                </li>
                              </ul>
                            </td>
                            <td className="d-none d-md-table-cell">Subscription</td>
                            <td className="text-end">75/mo</td>
                            <td className="text-end colAction d-none d-lg-block">
                              <a
                                href="#"
                                className="btn btn-colAction btn-chat"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Comments"
                              >
                                <i className="bi bi-chat-fill" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-secondary btn-colAction btn-users"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Attachments"
                              >
                                <i className="bi bi-paperclip" />
                                <span className="badge badge-danger">0</span>
                              </a>
                              <a
                                href="#"
                                className="btn btn-trash btn-colAction"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-animation="false"
                                title="Delete"
                              >
                                <i className="bi bi-trash-fill" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountingPanel
