import * as React from "react"
import "rc-slider/assets/index.css"
import _uniqueId from "lodash/uniqueId"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { PRIVACY_PRIVATE, PRIVACY_PUBLIC } from "../../../../services/globalStaticData"
import { NUMBER_RATTING_OPTIONS } from "../../../Global/Rattings/RattingHelper"
import AxiosInstance from "../../../../services/axiosInstance"

const CustomFieldNumberRatting = ({ contactId, customField, getContactData, setCustomFields }) => {
  const [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(parseInt(customField.data)),
    [oldInfoData, setOldInfoData] = useState(parseInt(customField.data)),
    [dataPrivacy, setDataPrivacy] = useState(customField.permission),
    [oldDataPrivacy, setOldDataPrivacy] = useState(customField.permission)

  let customFieldName = "contact_custom_fields_number_" + _uniqueId()
  let privacyIcon = customField.settingCustomField.icon
  let privacyLabel = "Public"

  if (dataPrivacy === PRIVACY_PRIVATE) {
    privacyIcon = customField.settingCustomField.icon + " privateInfo"
    privacyLabel = "Private"
  }

  const updatePrivacyInfo = () => {
    setDataPrivacy(dataPrivacy === PRIVACY_PRIVATE ? PRIVACY_PUBLIC : PRIVACY_PRIVATE)
    showSaveRowOptions()
  }

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setInfoData(oldInfoData)
    setDataPrivacy(oldDataPrivacy)
    setSaveOption(false)
  }

  const updateRowData = () => {
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/custom-field/edit/${customField.id}`, {
        type: customField.type,
        permission: dataPrivacy,
        value: infoData,
        settingCustomFieldId: customField.settingCustomField.id,
      })
        .then(function (response) {
          if (response.status === 200) {
            setOldDataPrivacy(dataPrivacy)
            setOldInfoData(infoData)
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  const deleteCustomField = customFieldId => {
    if (customFieldId) {
      return new Promise(async () => {
        await AxiosInstance.delete(`/contact/custom-field/delete/${customFieldId}`)
          .then(function (response) {
            if (response.status === 200) {
              setUpdateContact(true)
            }
          })
          .catch(function (error) {
            alert("there is some error please try again after some time.")
          })
      })
    }
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      setCustomFields([])
      getContactData(contactId)
      hideSaveRowOptions()
    }
  })

  return (
    <React.Fragment>
      <Form onSubmit={updateRowData}>
        <div className="CD_Info_Row border-bottom p-3" key={customFieldName}>
          <div className="row align-items-center">
            <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">
              {customField.settingCustomField.label}
            </div>

            <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
              <a
                data-bs-toggle="tooltip"
                data-placement="top"
                title={`${privacyLabel} Information`}
                onClick={updatePrivacyInfo}
              >
                <i className={privacyIcon} />
              </a>
            </div>

            <div className="col-sm-6 col-xs-12">
              <div className="rating rating-sm">
                <div className="rating-10stars">
                  <Form.Control
                    type="number"
                    name={customFieldName}
                    className="d-none"
                    value={infoData}
                    onChange={() => {}}
                  />

                  {NUMBER_RATTING_OPTIONS.map(optionData => {
                    let rowId = `rating-md-10-${optionData.index}-${_uniqueId()}`
                    let checked = infoData === optionData.index ? "checked" : ""
                    return (
                      <React.Fragment key={`number_ratting_${_uniqueId()}`}>
                        <Form.Control
                          type="radio"
                          className={`rating10-${optionData.index}`}
                          value={optionData.index}
                          id={rowId}
                          checked={checked}
                          onChange={() => {}}
                        />
                        <label
                          className={optionData.className}
                          title="Terrible"
                          htmlFor={rowId}
                          onClick={() => {
                            setInfoData(optionData.index)
                            showSaveRowOptions()
                          }}
                        >
                          {optionData.index}
                        </label>
                        &nbsp;
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
              {saveOption && (
                <React.Fragment>
                  <a
                    className="btnCDEdit"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    aria-label="Save"
                    onClick={() => {
                      updateRowData()
                    }}
                  >
                    <i className="bi bi-check2" />
                  </a>
                  <a
                    className="btnCDDelete"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    title="Cancel"
                    onClick={hideSaveRowOptions}
                  >
                    <i className="bi bi-x-lg" />
                  </a>
                </React.Fragment>
              )}
              {!saveOption && (
                <a
                  className="btnCDDelete"
                  data-bs-toggle="tooltip"
                  data-placement="top"
                  title="Delete"
                  onClick={() => deleteCustomField(customField.id)}
                >
                  <i className="bi bi-trash-fill" />
                </a>
              )}
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default CustomFieldNumberRatting
