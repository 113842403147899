import * as React from "react"
import { Link } from "gatsby"
import { useEffect, useRef, useState } from "react"
import AxiosInstance from "../../../services/axiosInstance.js"
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap"
import {
  CONTACT_PHONE_OPTIONS,
  CONTACT_ADDRESS_OPTIONS,
  DEFAULT_CONTACT_PHONE_OPTION,
  DEFAULT_CONTACT_ADDRESS_OPTION,
  TASK_EMAIL_OPTIONS,
  DEFAULT_TASK_EMAIL_OPTION,
} from "../../../services/globalStaticData.js"
import { getLoginUserId } from "../../../services/auth.js"
import PlacesAutocomplete from "react-places-autocomplete"
import { geocodeByAddress } from "react-places-autocomplete"
import { ADD_NEW_CONTACT, SEARCH_EXISTING_CONTACTS } from "../../Tasks/taskHelper.js"

const TaskContactForm = ({
  uploadedFile,
  setUploadedFile,
  defaultSelectedRole,
  setDefaultSelectedRole,
  setIsDisplayNewPerson,
  setSelectedPhoneType,
  setSelectedAddressType,
  existingContactId,
  setExistingContactId,
  defaultUserOptionSelected,
  setDefaultUserOptionSelected,
  address,
  setAddress,
  tempContactName,
  profilePic,
}) => {
  const [jobLabel, setJobLabel] = useState("Home"),
    [jobIcon, setJobIcon] = useState("briefcase-fill"),
    [addressIcon, setAddressIcon] = useState("house-door-fill"),
    [uploadedFilePath, setUploadedFilePath] = useState("StaticImages/avatar.gif"),
    [uploadFile, setUploadFile] = useState(false),
    [settingRole, setSettingRole] = useState([]),
    [selectedPhoneLabel, setSelectedPhoneLabel] = useState(DEFAULT_CONTACT_PHONE_OPTION.label),
    [selectedPhoneIcon, setSelectedPhoneIcon] = useState(DEFAULT_CONTACT_PHONE_OPTION.icon),
    [selectedAddressLabel, setSelectedAddressLabel] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.label),
    [selectedEmailLabel, setSelectedEmailLabel] = useState(DEFAULT_TASK_EMAIL_OPTION.label),
    [selectedEmailIcon, setSelectedEmailIcon] = useState(DEFAULT_TASK_EMAIL_OPTION.icon),
    [userSearchResult, setUserSearchResult] = useState([]),
    [existingUserDefaultName, setExistingUserDefaultName] = useState(tempContactName),
    [existingUserDefaultId, setExistingUserDefaultId] = useState(""),
    [existingUserDefaultImage, setExistingUserDefaultImage] = useState("")

  const companyPhotoRef = useRef(null)
  const contactImage = useRef(null)
  const openSelectImageBox = () => {
    contactImage.current.click()
  }

  const businessRoleTable = () => {
    AxiosInstance.get("/settings/role")
      .then(response => {
        if (response.data.items.length === 0) {
          setSettingRole(null)
        } else {
          setSettingRole(response.data.items)
          setDefaultSelectedRole(response.data.items[0]?.id)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleUserFieldDisplay = selectedValue => {
    if (selectedValue === ADD_NEW_CONTACT) {
      setDefaultUserOptionSelected(ADD_NEW_CONTACT)
    } else if (selectedValue === SEARCH_EXISTING_CONTACTS) {
      setDefaultUserOptionSelected(SEARCH_EXISTING_CONTACTS)
    }
  }

  const ChooseCompanyPhoto = () => {
    if (companyPhotoRef.current) {
      companyPhotoRef.current.click()
    }
  }

  const onImageChange = e => {
    setUploadedFile(e.target.files[0])
    setUploadFile(true)
  }

  const handleUpload = () => {
    let formData = new FormData()
    formData.append("ProfilePic", uploadedFile)
    formData.append("user_id", getLoginUserId())
    formData.append("parent_id", "0")
    AxiosInstance.post(`/settings/general-setting/upload-file-r2`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          setUploadedFilePath(response.data.profilePic.filePath)
          setUploadedFile(response.data.profilePic.filePath)
        }
      })
      .catch(function (error) {})
  }

  const handleChangeAddress = address => {
    console.log(address)
    setAddress(address)
  }

  const handleSelectAddress = address => {
    geocodeByAddress(address)
      .then(results => {
        setAddress(results[0].formatted_address)
      })
      .catch(error => console.error("Error", error))
  }

  const contactSearchData = e => {
    let searchUser = e.target.value
    if (searchUser.length < 2) {
      setUserSearchResult([])
      setExistingContactId("")
      return false
    }

    AxiosInstance.get(`/contact/`, {
      params: {
        page: 1,
        search: searchUser ?? "",
      },
    }).then(response => {
      const displayedItems = response.data.items.slice(0, 10)
      setUserSearchResult(displayedItems)
    })
  }

  useEffect(() => {
    businessRoleTable()
  }, [])

  useEffect(() => {
    if (uploadFile) {
      setUploadFile(false)
      handleUpload()
    }
  }, [uploadFile])

  return (
    <div className="socialSearchModal agendaPersonRoleRow mt-3">
      <div className="row align-items-center">
        <div className="col-lg-12 BC_Multiple_User position-relative">
          <a
            href="#"
            className="btnCloseAgendaPersonRoleRow"
            onClick={() => {
              setIsDisplayNewPerson(false)
            }}
          >
            <i className="bi bi-x-lg" />
          </a>
          <div className="BC_Multiple_User">
            <div className="d-block addNewSocialUserRow">
              <div className="row">
                <div className="col-lg-5 mb-1 mb-lg-0">
                  <select
                    className="form-select addNewTaskUserSelection"
                    defaultValue={defaultUserOptionSelected}
                    onChange={e => handleUserFieldDisplay(e.target.value)}
                  >
                    <option value={ADD_NEW_CONTACT}>Add New Contact</option>
                    <option value={SEARCH_EXISTING_CONTACTS}>Search Existing</option>
                  </select>
                </div>
                <div className="col-lg-3 mb-1 mb-lg-0">
                  <select
                    className="form-select"
                    defaultValue={defaultSelectedRole}
                    onChange={e => {
                      setDefaultSelectedRole(e.target.value)
                    }}
                  >
                    {settingRole.map((roleData, roleIndex) => (
                      <option key={roleIndex} value={roleData.id}>
                        {roleData.role}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4 mb-1 mb-lg-0 text-end">
                  <div className="dropdown socialActionShareWith">
                    <div className="dropdown">
                      <Dropdown>
                        <Dropdown.Toggle className="btn dropdown-toggle" type="button" variant="">
                          {" "}
                          <i className="bi bi-link-45deg"></i> Attached, Not Shared
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <i className="bi bi-link-45deg"></i> Attached, Not Shared
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-2 optionalMsgContainer d-none">
                  <input type="text" className="form-control" placeholder="Optional Message " />
                </div>
              </div>

              <div
                className={`row mt-2 searchExistingUserField ${
                  defaultUserOptionSelected === SEARCH_EXISTING_CONTACTS ? "d-block" : "d-none"
                }`}
              >
                <div className="col-lg-8">
                  <div className="bc-wrapper">
                    <input
                      type="hidden"
                      id="agendaPersonRoleSearchId"
                      name="agendaPersonRoleSearchId"
                      defaultValue={existingUserDefaultId}
                    />
                    <input
                      type="text"
                      className={`form-control ${existingContactId ? "userSelectedInput" : ""} `}
                      name="agendaPersonRoleSearch"
                      placeholder="Name, Phone or Email"
                      autoComplete="off"
                      onKeyUp={contactSearchData}
                      data-query={existingUserDefaultName}
                      value={existingUserDefaultName}
                      onChange={e => setExistingUserDefaultName(e.target.value)}
                    />

                    {userSearchResult.length > 0 && (
                      <div className="bc-menu list-group" style={{ display: "block" }}>
                        {userSearchResult.map((searchUserData, searchUserIndex) => {
                          return (
                            <a
                              // href="#"
                              className="list-group-item"
                              data-id={searchUserData.id}
                              data-label={searchUserData.name}
                              key={searchUserIndex}
                              onClick={() => {
                                const defaultImageUrl = `${process.env.GATSBY_WRANGLER_URL}/${
                                  searchUserData.profilePic ? searchUserData.profilePic : uploadedFilePath
                                }`
                                setExistingContactId(searchUserData.id)
                                setExistingUserDefaultName(searchUserData.name)
                                setExistingUserDefaultImage(defaultImageUrl)
                                setUserSearchResult([])
                                setExistingUserDefaultId(searchUserData.id)
                              }}
                            >
                              <img
                                className="bc-user-avatar"
                                src={`${process.env.GATSBY_WRANGLER_URL}/${
                                  searchUserData.profilePic ? searchUserData.profilePic : uploadedFilePath
                                }`}
                              />
                              {searchUserData.name}
                            </a>
                          )
                        })}
                      </div>
                    )}

                    {existingContactId && (
                      <img
                        className="selectedUserAvatar"
                        src={profilePic ?? existingUserDefaultImage}
                        alt={existingUserDefaultName}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div
                className={`row addNewUserField ${
                  defaultUserOptionSelected === ADD_NEW_CONTACT ? "d-block" : "d-none"
                }`}
              >
                <div className="col-lg-12 mt-2">
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-6 mb-3 mb-md-0">
                            <div className="input-group">
                              <OverlayTrigger placement="top" overlay={<Tooltip>Human</Tooltip>}>
                                <button className="btn addContactUserType isHuman">
                                  <i className="bi bi-person-fill" />
                                </button>
                              </OverlayTrigger>

                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Name"
                                name="contactName"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group">
                              <div className="dropdown addNewPhoneBox">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="btn btn-default dropdown-toggle"
                                    type="button"
                                    variant=""
                                  >
                                    {" "}
                                    <i className={selectedPhoneIcon} />
                                    <span>{selectedPhoneLabel}</span>{" "}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {CONTACT_PHONE_OPTIONS.map((phoneData, phoneDataIndex) => {
                                      return (
                                        <Dropdown.Item
                                          href="#"
                                          key={phoneDataIndex}
                                          onClick={() => {
                                            setSelectedPhoneIcon(phoneData.icon)
                                            setSelectedPhoneLabel(phoneData.label)
                                            setSelectedPhoneType(phoneData.type)
                                          }}
                                        >
                                          <i className={phoneData.icon} /> {phoneData.label}
                                        </Dropdown.Item>
                                      )
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <input
                                type="text"
                                className="form-control contact_phone_us"
                                placeholder="Phone"
                                name="contactPhone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 addContactUserTypeContactRow d-none">
                        <div className="input-group">
                          <div className="input-group-text">
                            <img
                              alt="Contact avatar"
                              className="contact_pic"
                              src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/avatar.gif`}
                            />
                            <input
                              type="file"
                              accept="image/*"
                              className="contactPhotoFile"
                              style={{
                                visibility: "hidden",
                                position: "absolute",
                                left: "-10000px",
                              }}
                            />

                            <OverlayTrigger overlay={<Tooltip>Contact avatar</Tooltip>}>
                              <Link to={"#"} className="btnEditAvatar">
                                <i className="bi bi-pencil-fill" />
                              </Link>
                            </OverlayTrigger>
                          </div>
                          <input
                            type="text"
                            className="form-control CD_input_info"
                            placeholder="Contact Name"
                          />
                        </div>
                      </div>
                      <div className="mb-3 addContactUserTypeCompanyRow">
                        <div className="row">
                          <div className="col-md-6 mb-3 mb-md-0">
                            <div className="input-group">
                              <div className="input-group-text">
                                <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/no-image.jpg`} />
                                <input
                                  ref={companyPhotoRef}
                                  type="file"
                                  accept="image/*"
                                  className="companyPhotoFile"
                                  style={{
                                    visibility: "hidden",
                                    position: "absolute",
                                    left: "-10000px",
                                  }}
                                />

                                {/* <OverlayTrigger overlay={<Tooltip>Company Photo</Tooltip>}>
                                <Link
                                    to={"#"}
                                    className="btnAddCompanyPhoto"
                                    onClick="if (!window.__cfRLUnblockHandlers) return false; $('.companyPhotoFile').trigger('click')"
                                >
                                    <i className="bi bi-pencil-fill" />
                                </Link>
                                </OverlayTrigger> */}

                                <OverlayTrigger overlay={<Tooltip>Company Photo</Tooltip>}>
                                  <Link
                                    to="#"
                                    className="btnAddCompanyPhoto"
                                    onClick={e => {
                                      e.preventDefault()
                                      ChooseCompanyPhoto()
                                    }}
                                  >
                                    <i className="bi bi-pencil-fill" />
                                  </Link>
                                </OverlayTrigger>
                              </div>
                              <input
                                type="text"
                                className="form-control CD_input_info contact_company"
                                placeholder="Company"
                                name="companyName"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group">
                              <div className="dropdown addNewPhoneBox">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="btn btn-default dropdown-toggle"
                                    type="button"
                                    variant=""
                                  >
                                    {" "}
                                    <i className={`bi bi-${jobIcon}`} />
                                    <span>{jobLabel}</span>{" "}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setJobIcon("house-door-fill")
                                        setJobLabel("Mailing")
                                      }}
                                    >
                                      <i className="bi bi-house-door-fill" /> Mailing
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setJobIcon("house-door-fill")
                                        setJobLabel("Billing")
                                      }}
                                    >
                                      <i className="bi bi-house-door-fill" /> Billing
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setJobIcon("house-door-fill")
                                        setJobLabel("Work")
                                      }}
                                    >
                                      <i className="bi bi-house-door-fill" /> Work
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setJobIcon("house-door-fill")
                                        setJobLabel("Other")
                                      }}
                                    >
                                      <i className="bi bi-house-door-fill" /> Other
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Job Title"
                                name="jobTitle"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-6 mb-3 mb-md-0">
                            <div className="input-group">
                              <div className="dropdown addNewPhoneBox">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="btn btn-default dropdown-toggle"
                                    type="button"
                                    variant=""
                                  >
                                    {" "}
                                    <i className={selectedEmailIcon} />
                                    <span>{selectedEmailLabel}</span>{" "}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {TASK_EMAIL_OPTIONS.map((emailData, emailDataIndex) => {
                                      return (
                                        <Dropdown.Item
                                          href="#"
                                          key={emailDataIndex}
                                          onClick={() => {
                                            setSelectedEmailIcon(emailData.icon)
                                            setSelectedEmailLabel(emailData.label)
                                            // setSelectedEmailType(emailData.type);
                                          }}
                                        >
                                          <i className={emailData.icon} /> {emailData.label}
                                        </Dropdown.Item>
                                      )
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <input
                                type="text"
                                className="form-control CD_input_info contact_email"
                                placeholder="Email"
                                name="contactEmail"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group">
                              <div className="dropdown addNewPhoneBox">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="btn btn-default dropdown-toggle"
                                    type="button"
                                    variant=""
                                  >
                                    {" "}
                                    <i className={`bi bi-${addressIcon}`} />
                                    <span>{selectedAddressLabel}</span>{" "}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {CONTACT_ADDRESS_OPTIONS.map((addressData, addressDataIndex) => {
                                      return (
                                        <Dropdown.Item
                                          href="#"
                                          key={addressDataIndex}
                                          onClick={() => {
                                            setSelectedAddressLabel(addressData.label)
                                            setSelectedAddressType(addressData.type)
                                          }}
                                        >
                                          <i className={addressData.icon} /> {addressData.label}
                                        </Dropdown.Item>
                                      )
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>

                              <PlacesAutocomplete
                                value={address}
                                onChange={handleChangeAddress}
                                onSelect={handleSelectAddress}
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                  <>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Search Places ...",
                                        className: "location-search-input form-control",
                                        name: "address",
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item"
                                        const style = suggestion.active
                                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                          : { backgroundColor: "#ffffff", cursor: "pointer" }
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                            key={index}
                                          >
                                            <span>{suggestion.description}</span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </>
                                )}
                              </PlacesAutocomplete>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="addNewContactAvatar d-inline-block">
                        <img
                          src={`${process.env.GATSBY_WRANGLER_URL}${uploadedFilePath}`}
                          alt="Contact avatar"
                          className="contact_pic"
                        />
                        <OverlayTrigger placement="top" overlay={<Tooltip>Contact avatar</Tooltip>}>
                          <a className="btnEditAvatar" onClick={openSelectImageBox}>
                            <span className="d-block" data-bs-toggle="tooltip" title="Contact avatar">
                              <i className="bi bi-pencil-fill" />
                            </span>
                          </a>
                        </OverlayTrigger>
                        <input type="file" ref={contactImage} onChange={onImageChange} hidden />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TaskContactForm
