import * as React from "react"
import { useEffect, useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"

const TitleName = ({ entityId, api, name, tempName, setTempName, getContactData }) => {
  const [updateName, setUpdateName] = useState(false)
  const [nameError, setNameError] = useState("")

  const saveGroupName = () => {
    if (entityId) {
      if (tempName) {
        return new Promise(async () => {
          await AxiosInstance.post(`${api}/update-name/${entityId}`, {
            name: tempName,
          })
            .then(function (response) {
              getContactData(entityId)
            })
            .catch(function (error) {})
        })
      } else {
        setNameError("Please add Valid Name")
      }
    }
  }

  useEffect(() => {
    if (updateName) {
      setUpdateName(false)
      if (name !== tempName) {
        saveGroupName()
      }
    }
  })

  return (
    <React.Fragment>
      <input
        type="text"
        className="form-control"
        placeholder="Group Name"
        value={tempName}
        onChange={e => setTempName(e.target.value)}
        onBlur={e => setUpdateName(true)}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setUpdateName(true)
          }
        }}
      />
      {nameError && <div className="error-text"> {nameError} </div>}
    </React.Fragment>
  )
}

export default TitleName
