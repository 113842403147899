import * as React from "react"
import { useEffect, useState } from "react"
import { Form, FormSelect, OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"

const PrimaryCategory = ({
  contactId,
  primaryCategory,
  tempPrimaryCategory,
  setPrimaryCategory,
  setTempPrimaryCategory,
  industryData,
}) => {
  const fieldName = "contact_primary_category_select",
    [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(primaryCategory),
    [oldInfoData, setOldInfoData] = useState(primaryCategory)

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setInfoData(oldInfoData)
    setSaveOption(false)
  }

  const updateRowData = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    if (
      formData.get(fieldName) === "" ||
      formData.get(fieldName) === undefined ||
      formData.get(fieldName) === null
    ) {
      alert("please select valid Industry.")
      return false
    } else {
      return new Promise(async () => {
        await AxiosInstance.post(`/contact/update-primary-category/${contactId}`, {
          primaryCategory: formData.get(fieldName),
        })
          .then(function (response) {
            if (response.status === 200) {
              setPrimaryCategory(formData.get(fieldName))
              setInfoData(formData.get(fieldName))
              setOldInfoData(formData.get(fieldName))
              hideSaveRowOptions()
              setUpdateContact(true)
            }
          })
          .catch(function (error) {
            alert("There is some error Please try after sometime.")
          })
      })
    }
  }

  const deletePrimaryCategory = () => {
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/update-primary-category/${contactId}`, {
        primaryCategory: "",
      })
        .then(function (response) {
          if (response.status === 200) {
            setPrimaryCategory("")
            setTempPrimaryCategory("")
            setInfoData("")
            setOldInfoData("")
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      hideSaveRowOptions()
    }
  })

  return (
    <React.Fragment>
      <Form onSubmit={updateRowData} style={{ marginBottom: 0 }}>
        <div className="CD_Info_Row border-bottom p-3">
          <div className="row align-items-center">
            <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">Primary Category</div>
            <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
              <OverlayTrigger placement="top" overlay={<Tooltip>Private Information</Tooltip>}>
                <span>
                  <i className="bi bi-building-fill" />
                </span>
              </OverlayTrigger>
            </div>

            {!saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">{primaryCategory}</div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Search</Tooltip>}>
                    <a
                      className="btnCDSearch"
                      href={`https://www.google.com/search?q=${encodeURIComponent(primaryCategory)}`}
                      target="_blank"
                    >
                      <i className="bi bi-search" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                    <a className="btnCDEdit" onClick={showSaveRowOptions}>
                      <i className="bi bi-pencil-fill" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>}>
                    <a className="btnCDDelete" onClick={() => deletePrimaryCategory()}>
                      <i className="bi bi-trash-fill" />
                    </a>
                  </OverlayTrigger>
                </div>
              </>
            )}

            {saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">
                  <select className="form-select" aria-label="Default select example" name={fieldName}>
                    <option value={""}> == INDUSTRY == </option>
                    {industryData.length > 0 && (
                      <>
                        {industryData.map(industry => {
                          let selected = industry.name === infoData ? "selected" : ""
                          return (
                            <option value={industry.name} selected={selected} key={industry.id}>
                              {industry.name}
                            </option>
                          )
                        })}
                      </>
                    )}
                  </select>
                </div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Save</Tooltip>}>
                    <a className="btnCDEdit" type={"submit"}>
                      <i className="bi bi-check2" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>}>
                    <a className="btnCDDelete" onClick={hideSaveRowOptions}>
                      <i className="bi bi-x-lg" />
                    </a>
                  </OverlayTrigger>
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default PrimaryCategory
