import * as React from "react"
import DriveTable from "../../../Drive/diveTable"
import { VIEW_STATE_OPEN, VIEW_STATE_SHOW } from "../../../../services/globalHelpers"
import DriveLazyLoad from "../../../Drive/driveLazyLoad"
import { useState, useRef } from "react"
import { onGrabData } from "../../../Drive/Service/driveListHelper"
import clsx from "clsx"
import AxiosInstance from "../../../../services/axiosInstance"
import { getLoginUserId } from "../../../../services/auth"

const FilesPanel = ({ driveWorkRoom, handleViewState, viewState }) => {
  const selectedDocId = driveWorkRoom[0]?.id
  const triggerRef = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [uploadingData, setUploadingData] = useState([])
  const [selectedFile, setSelectedFile] = useState([])
  const convertNestedObjectToArray = nestedObj => Object.keys(nestedObj).map(key => nestedObj[key])

  const { data, loading } = DriveLazyLoad({
    triggerRef,
    onGrabData,
    selectedDocId,
    searchTerm,
    setSearchTerm,
  })

  const inputFile = useRef(null)
  const onButtonClick = () => {
    inputFile.current.click()
  }

  const handleNewFileUpload = e => {
    const { files: newFiles } = e.target
    const updatedFiles = newFiles
    let existingData = [...uploadingData]

    for (const updatedFile of updatedFiles) {
      existingData.push({ name: updatedFile.name })
    }
    setUploadingData(existingData)
    handleSubmit(convertNestedObjectToArray(newFiles))
  }

  const handleSubmit = newFiles => {
    return new Promise(async resolve => {
      await AxiosInstance.post(
        `/drive/file-upload`,
        {
          user_id: getLoginUserId(),
          drive_all_files: newFiles,
          parent_id: selectedDocId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(function (response) {
          if (response.status === 201) {
            console.log(response.status)
          }
          window.location.reload()
          return response.data
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  }

  return (
    <div className="col-lg-12 mb-3" id="files_panel">
      <div className="accordion accordion-item panel panel-beige">
        <div
          className={`accordion-button${viewState.file !== VIEW_STATE_OPEN ? " collapsed" : ""}`}
          data-bs-toggle="collapse"
          data-bs-target="#files_container"
          aria-expanded="false"
          aria-controls="files_container"
          onClick={() => {
            handleViewState("file", viewState.file === VIEW_STATE_SHOW ? VIEW_STATE_OPEN : VIEW_STATE_SHOW)
          }}
        >
          <span className="panel-heading-icon">
            <i className="bi bi-paperclip"></i>
          </span>
          <span className="panel-heading-title">Files</span>
        </div>
        <div
          id="files_container"
          className={`accordion-collapse collapse${viewState.file === VIEW_STATE_OPEN ? " show" : ""}`}
          aria-labelledby="files_container"
        >
          <div className="accordion-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                    <li className="nav-item">
                      <button type="button" className="btn btn-secondary btnUpload" onClick={onButtonClick}>
                        <i className="bi bi-cloud-arrow-up-fill" />{" "}
                        <span className="d-none d-md-inline">Upload</span>
                      </button>
                      <input
                        type="file"
                        className="d-none"
                        ref={inputFile}
                        onChange={handleNewFileUpload}
                        multiple
                      />
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="drive-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#drive"
                        role="tab"
                        aria-controls="drive"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Drive">
                          <i className="bi bi-database-fill"></i>
                        </span>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="small-icon-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#small-icon"
                        role="tab"
                        aria-controls="small-icon"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Small Icons">
                          <i className="bi bi-ui-checks-grid"></i>
                        </span>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="large-icon-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#large-icon"
                        role="tab"
                        aria-controls="large-icon"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Large Icons">
                          <i className="bi bi-stop-fill"></i>
                        </span>
                      </a>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <a
                        className="nav-link"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="View Templates"
                      >
                        <i className="bi bi-journals"></i>
                      </a>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <a
                        className="nav-link"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="Trash"
                      >
                        <i className="bi bi-trash-fill"></i>
                      </a>
                    </li>
                  </ul>

                  <ul className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1" role="tablist">
                    <li className="nav-item">
                      <button type="button" className="btn btn-secondary btnUpload me-1">
                        <i className="bi bi-cloud-arrow-up-fill"></i>
                      </button>
                    </li>
                    <li className="nav-item">
                      <div className="btn-group btnDriveView" role="group">
                        <button
                          id="btnGroupDrop3"
                          type="button"
                          className="btn btn-default dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-database-fill"></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop3">
                          <li>
                            <a
                              className="dropdown-item"
                              id="drive-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#drive"
                              role="tab"
                              aria-controls="drive"
                              aria-selected="false"
                            >
                              <i className="bi bi-database-fill"></i> Drive
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              id="small-icon-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#small-icon"
                              role="tab"
                              aria-controls="small-icon"
                              aria-selected="false"
                            >
                              <i className="bi bi-ui-checks-grid"></i> Small Icons
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              id="large-icon-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#large-icon"
                              role="tab"
                              aria-controls="large-icon"
                              aria-selected="false"
                            >
                              <i className="bi bi-stop-fill"></i> Large Icons
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-journals"></i> Templates
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-trash-fill"></i> Trash
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <DriveTable
                data={data}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                parentId={selectedDocId}
                uploadingData={uploadingData}
              />
            </div>
            <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilesPanel
