import * as React from "react"
import { useEffect, useState } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"

const JobRole = ({ contactId, jobRole, setJobRole }) => {
  const fieldName = "contact_job_role_input",
    [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(jobRole),
    [oldInfoData, setOldInfoData] = useState(jobRole)

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setInfoData(oldInfoData)
    setSaveOption(false)
  }

  const updateRowData = e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    return new Promise(async () => {
      await AxiosInstance.post(`/contact/update-job-role/${contactId}`, {
        value: formData.get(fieldName),
      })
        .then(function (response) {
          if (response.status === 200) {
            setJobRole(formData.get(fieldName))
            setInfoData(formData.get(fieldName))
            setOldInfoData(formData.get(fieldName))
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  const deleteJobRole = () => {
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/update-job-role/${contactId}`, {
        value: "",
      })
        .then(function (response) {
          if (response.status === 200) {
            setJobRole("")
            setInfoData("")
            setOldInfoData("")
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      hideSaveRowOptions()
    }
  })

  return (
    <React.Fragment>
      <Form onSubmit={updateRowData} style={{ marginBottom: 0 }}>
        <div className="CD_Info_Row border-bottom p-3">
          <div className="row align-items-center">
            <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">Job Role</div>

            <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
              <OverlayTrigger placement="top" overlay={<Tooltip>Private Information</Tooltip>}>
                <span>
                  <i className="bi bi-building" />
                </span>
              </OverlayTrigger>
            </div>

            <div className="col-sm-6 col-xs-12">
              {!saveOption && <div> {jobRole} </div>}
              {saveOption && (
                <input
                  className="form-control"
                  name={fieldName}
                  defaultValue={infoData}
                  onClick={e => {
                    setInfoData(e.target.value)
                  }}
                />
              )}
            </div>

            {saveOption && (
              <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                <OverlayTrigger placement="top" overlay={<Tooltip>Save</Tooltip>}>
                  <a className="btnCDEdit" type={"submit"}>
                    <i className="bi bi-check2" />
                  </a>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>}>
                  <a className="btnCDDelete" onClick={hideSaveRowOptions}>
                    <i className="bi bi-x-lg" />
                  </a>
                </OverlayTrigger>
              </div>
            )}
            {!saveOption && (
              <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                <OverlayTrigger placement="top" overlay={<Tooltip>Search</Tooltip>}>
                  <a
                    className="btnCDSearch"
                    href={`https://www.google.com/search?q=${encodeURIComponent(jobRole)}`}
                    target="_blank"
                  >
                    <i className="bi bi-search" />
                  </a>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                  <a className="btnCDEdit" onClick={showSaveRowOptions}>
                    <i className="bi bi-pencil-fill" />
                  </a>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                  <a className="btnCDDelete" onClick={() => deleteJobRole()}>
                    <i className="bi bi-trash-fill" />
                  </a>
                </OverlayTrigger>
              </div>
            )}
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default JobRole
