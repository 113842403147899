import * as React from "react"
import { useEffect, useState } from "react"
import {
  CONTACT_META_DATE_FIELDS,
  CONTACT_META_DROPDOWN_FIELDS,
  CONTACT_META_FIELD_OPTIONS,
  CONTACT_META_SOCIAL_LINK_FIELDS,
  CONTACT_META_TEXT_FIELDS,
  CONTACT_META_TEXTAREA_FIELDS,
  getTypeData,
  PRIVACY_PRIVATE,
  PRIVACY_PUBLIC,
} from "../../../services/globalStaticData"
import _uniqueId from "lodash/uniqueId"
import DatePicker from "react-datepicker"
import AxiosInstance from "../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const MetaData = ({ contactId, data, setMetaData, getContactData, industryData }) => {
  const [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(data.value),
    [oldInfoData, setOldInfoData] = useState(data.value),
    [dataPrivacy, setDataPrivacy] = useState(data.permission),
    [oldDataPrivacy, setOldDataPrivacy] = useState(data.permission)

  let metaDataFieldName = "contact_meta_data_fields_" + _uniqueId()
  let metaDataType = getTypeData(CONTACT_META_FIELD_OPTIONS, data.type)
  let iconClasses = metaDataType.icon
  let privacyLabel = "Public"

  if (dataPrivacy === PRIVACY_PRIVATE) {
    iconClasses = iconClasses + " privateInfo"
    privacyLabel = "Private"
  }

  const updatePrivacyInfo = () => {
    setDataPrivacy(dataPrivacy === PRIVACY_PRIVATE ? PRIVACY_PUBLIC : PRIVACY_PRIVATE)
    showSaveRowOptions()
  }

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setInfoData(oldInfoData)
    setDataPrivacy(oldDataPrivacy)
    setSaveOption(false)
  }

  const updateRowData = () => {
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/meta-data/edit/${data.id}`, {
        type: data.type,
        permission: dataPrivacy,
        value: infoData,
      })
        .then(function (response) {
          if (response.status === 200) {
            setOldDataPrivacy(dataPrivacy)
            setOldInfoData(infoData)
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  const deleteLink = metaDataId => {
    if (metaDataId) {
      return new Promise(async () => {
        await AxiosInstance.delete(`/contact/meta-data/delete/${metaDataId}`)
          .then(function (response) {
            if (response.status === 200) {
              setUpdateContact(true)
            }
          })
          .catch(function (error) {
            if (error.response.status === 400) {
              // setNotesTextAreaError(error.response.data.message)
            } else {
              // setNotesTextAreaError(error.response.data.detail)
            }
          })
      })
    }
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      setMetaData([])
      getContactData(contactId)
      hideSaveRowOptions()
    }
  })
  return (
    <React.Fragment key={`contact_detail_meta_data_${data.id}`}>
      {metaDataType && data.value && (
        <div className="CD_Info_Row border-bottom p-3">
          <div className="row align-items-center">
            <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">{metaDataType.label}</div>
            <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
              <a
                data-bs-toggle="tooltip"
                data-placement="top"
                title={`${privacyLabel} Information`}
                onClick={updatePrivacyInfo}
              >
                <i className={iconClasses} />
              </a>
            </div>
            {!saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">
                  {(CONTACT_META_DROPDOWN_FIELDS.indexOf(metaDataType.type) !== -1 ||
                    CONTACT_META_TEXT_FIELDS.indexOf(metaDataType.type) !== -1 ||
                    CONTACT_META_TEXTAREA_FIELDS.indexOf(metaDataType.type) !== -1) && <>{data.value}</>}
                  {CONTACT_META_SOCIAL_LINK_FIELDS.indexOf(metaDataType.type) !== -1 && (
                    <a href={data.value} target="_blank" className="btnOpenChatDock">
                      {data.value}
                    </a>
                  )}
                  &nbsp;
                  {CONTACT_META_SOCIAL_LINK_FIELDS.indexOf(metaDataType.type) !== -1 && (
                    <div className="showOnHover d-inline-block">
                      <a
                        href={data.value}
                        title={data.value}
                        target="_blank"
                        className="btn btn-secondary btnQuickAction btnOpenChatDock"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                      >
                        <i className="bi bi-box-arrow-up-right" />
                      </a>
                    </div>
                  )}
                </div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Search</Tooltip>}>
                    <a
                      className="btnCDSearch"
                      href={`https://www.google.com/search?q=${encodeURIComponent(data.value)}`}
                      target="_blank"
                    >
                      <i className="bi bi-search" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                    <a className="btnCDEdit" onClick={showSaveRowOptions}>
                      <i className="bi bi-pencil-fill" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                    <a className="btnCDDelete" onClick={() => deleteLink(data.id)}>
                      <i className="bi bi-trash-fill" />
                    </a>
                  </OverlayTrigger>
                </div>
              </>
            )}

            {saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">
                  {CONTACT_META_DROPDOWN_FIELDS.indexOf(metaDataType.type) !== -1 && (
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name={metaDataFieldName}
                    >
                      <option value={""} onClick={() => setInfoData(null)}>
                        == INDUSTRY ==
                      </option>
                      {industryData.length > 0 && (
                        <>
                          {industryData.map(industry => {
                            let selected = industry.name === infoData ? "selected" : ""
                            return (
                              <option
                                value={industry.name}
                                selected={selected}
                                onClick={() => setInfoData(industry.name)}
                              >
                                {industry.name}
                              </option>
                            )
                          })}
                        </>
                      )}
                    </select>
                  )}

                  {CONTACT_META_TEXTAREA_FIELDS.indexOf(metaDataType.type) !== -1 && (
                    <textarea
                      className="form-control"
                      name={metaDataFieldName}
                      style={{ height: "100px" }}
                      onChange={e => {
                        setInfoData(e.target.value)
                      }}
                    />
                  )}

                  {CONTACT_META_DROPDOWN_FIELDS.indexOf(metaDataType.type) === -1 &&
                    CONTACT_META_TEXTAREA_FIELDS.indexOf(metaDataType.type) === -1 && (
                      <input
                        type="text"
                        className="form-control CD_input_info"
                        value={infoData}
                        defaultValue={infoData}
                        name={metaDataFieldName}
                        placeholder={metaDataType.placeholder}
                        onChange={e => {
                          setInfoData(e.target.value)
                        }}
                        onMouseLeave={e => {
                          setInfoData(e.target.value)
                        }}
                      />
                    )}
                </div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <a
                    className="btnCDEdit"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    aria-label="Save"
                    onClick={updateRowData}
                  >
                    <i className="bi bi-check2" />
                  </a>
                  <a
                    className="btnCDDelete"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    title="Cancel"
                    onClick={hideSaveRowOptions}
                  >
                    <i className="bi bi-x-lg" />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default MetaData
