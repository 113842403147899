import * as React from "react"
import Dropdown from "react-bootstrap/Dropdown"
import { useEffect, useState } from "react"
import _uniqueId from "lodash/uniqueId"
import {
  PRIVACY_PUBLIC,
  PRIVACY_PRIVATE,
  CONTACT_META_FIELD_OPTIONS,
  DEFAULT_CONTACT_PHONE_OPTION,
  CONTACT_META_DROPDOWN_FIELDS,
  CONTACT_META_DATE_FIELDS,
  CONTACT_META_TEXTAREA_FIELDS,
} from "../../../services/globalStaticData"
import {
  CUSTOM_ADDRESS,
  NUMBER_RATTING,
  SLIDER_RATTING,
  STAR_RATTING,
  CUSTOM_NUMBER,
  CUSTOM_OPTIONS,
  CUSTOM_TEXTAREA,
  CUSTOM_URL,
} from "../contactHelper"
import StarRatting from "../../Global/Rattings/starRatting"
import SliderRatting from "../../Global/Rattings/sliderRatting"
import NumberRatting from "../../Global/Rattings/numberRatting"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import AxiosInstance from "../../../services/axiosInstance"

const AddDetailRow = ({
  contact,
  removeNewDataRow,
  settingCustomFields,
  dataRowIndex,
  getContactData,
  primaryCategory,
  industryData,
  companyName,
  jobTitle,
  jobRole,
}) => {
  const dataRowName = `contact_data_row_${_uniqueId()}`,
    [infoType, setInfoType] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),
    [infoPrivacy, setInfoPrivacy] = useState(PRIVACY_PUBLIC),
    [infoData, setInfoData] = useState(""),
    [defaultValue, setDefaultValue] = useState(""),
    [customFieldOptions, setCustomFieldOptions] = useState(""),
    [dataRowIcon, setDataRowIcon] = useState(DEFAULT_CONTACT_PHONE_OPTION.icon),
    [dataRowLabel, setDataRowLabel] = useState(DEFAULT_CONTACT_PHONE_OPTION.label),
    [dataRowApiRoute, setDataRowApiRoute] = useState(DEFAULT_CONTACT_PHONE_OPTION.contactApi),
    [dataRowError, setDataRowError] = useState(""),
    [customFieldId, setCustomFieldId] = useState(null),
    [dataRowInputPlaceHolder, setDataRowInputPlaceHolder] = useState(
      DEFAULT_CONTACT_PHONE_OPTION.placeHolder
    ),
    [removeDataRow, setRemoveDataRow] = useState(false)
  const saveRowData = () => {
    if (infoData && infoType && dataRowApiRoute && contact.id) {
      setDataRowError("")

      var data = {
        type: infoType,
        permission: infoPrivacy,
        value: infoData,
      }

      if (customFieldId) {
        data.settingCustomFieldId = customFieldId
      }

      if (infoType === "category_primary") {
        data.primaryCategory = infoData
      }

      return new Promise(async () => {
        await AxiosInstance.post(dataRowApiRoute + contact.id, data)
          .then(function (response) {
            if (
              response.status === 201 ||
              (response.status === 200 &&
                ["category_primary", "company_name", "job_title", "job_role"].indexOf(infoType) > -1)
            ) {
              setRemoveDataRow(true)
            }
          })
          .catch(function (error) {
            let message = "There is some error please check the data."
            if (error.response.status === 400) {
              if (error.response.data.phone) {
                message = error.response.data.phone
              } else if (error.response.data.email) {
                message = error.response.data.email
              } else if (error.response.data.url) {
                message = error.response.data.url
              } else {
                message = error.response.data.detail
              }
            }
            setDataRowError(message)
          })
      })
    }
  }

  useEffect(() => {
    if (removeDataRow) {
      removeNewDataRow(dataRowIndex)
      getContactData(contact.id)
    }
  })

  return (
    <div className="CD_Info_Row border-bottom p-3 CD_Info_Row_1670502681307">
      <div className="row align-items-center">
        <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              <i className={dataRowIcon} />
              &nbsp;{dataRowLabel}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ maxHeight: "250px", overflow: "auto" }}>
              {CONTACT_META_FIELD_OPTIONS.map((fieldOption, index) => {
                // START : skipping contact entity fields if they are available;
                if (
                  (fieldOption.type === "company_name" && companyName !== "" && companyName !== null) ||
                  (fieldOption.type === "job_title" && jobTitle !== "" && jobTitle !== null) ||
                  (fieldOption.type === "job_role" && jobRole !== "" && jobRole !== null)
                ) {
                  return false
                }
                // END : skipping contact entity fields if they are available;

                let style = fieldOption.type === "" ? { color: "#23272b", fontWeight: 600 } : {}
                let addOption = true

                if (["category_primary", "category_additional"].indexOf(fieldOption.type) !== -1) {
                  if (fieldOption.type === "category_primary" && primaryCategory) {
                    addOption = false
                  }
                  if (fieldOption.type === "category_additional" && !primaryCategory) {
                    addOption = false
                  }
                }

                if (addOption) {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={e => {
                        setCustomFieldId(null)
                        setInfoType(fieldOption.type)
                        setDataRowIcon(fieldOption.icon)
                        setDataRowLabel(fieldOption.label)
                        setDataRowInputPlaceHolder(fieldOption.placeHolder)
                        setDataRowApiRoute(fieldOption.contactApi)
                        setDefaultValue("")

                        if (CONTACT_META_DATE_FIELDS.indexOf(fieldOption.type) !== -1) {
                          setInfoData(new Date())
                        } else {
                          setInfoData("")
                        }
                      }}
                      disabled={fieldOption.type === ""}
                      style={style}
                    >
                      {fieldOption.type !== "" && (
                        <React.Fragment>
                          <i className={fieldOption.icon} />
                          &nbsp;&nbsp;
                        </React.Fragment>
                      )}
                      {fieldOption.label}
                    </Dropdown.Item>
                  )
                }
              })}

              {settingCustomFields.length > 0 && (
                <React.Fragment>
                  <Dropdown.Item
                    key={"custom_field_options"}
                    disabled={true}
                    style={{ color: "#23272b", fontWeight: 600 }}
                  >
                    == CUSTOM FIELDS ==
                  </Dropdown.Item>
                  {settingCustomFields.map((customField, optionIndex) => (
                    <Dropdown.Item
                      key={optionIndex}
                      onClick={e => {
                        setCustomFieldId(customField.id)
                        setInfoType(customField.type)
                        setDataRowIcon(customField.icon)
                        setDataRowLabel(customField.label)
                        setDataRowInputPlaceHolder("")
                        setDefaultValue("")
                        setDataRowApiRoute("/contact/custom-field/add/")
                        setCustomFieldOptions(customField.defaultValue)
                      }}
                    >
                      <i className={customField.icon} />
                      &nbsp;&nbsp;{customField.label}
                    </Dropdown.Item>
                  ))}
                </React.Fragment>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
          <span
            data-bs-toggle="tooltip"
            aria-label="Private Information"
            onClick={() => {
              setInfoPrivacy(infoPrivacy === PRIVACY_PRIVATE ? PRIVACY_PUBLIC : PRIVACY_PRIVATE)
            }}
          >
            <i
              className={
                infoPrivacy === PRIVACY_PRIVATE ? "bi bi-eye-slash-fill privateInfo" : "bi bi-eye-slash-fill"
              }
            />
          </span>
        </div>

        <div className="col-sm-6 col-xs-12">
          {customFieldId && (
            <React.Fragment>
              {(infoType === CUSTOM_ADDRESS || infoType === CUSTOM_TEXTAREA) && (
                <textarea
                  className="form-control"
                  name={dataRowName}
                  style={{ height: "100px" }}
                  onChange={e => {
                    setInfoData(e.target.value)
                  }}
                />
              )}

              {infoType === CUSTOM_OPTIONS && customFieldOptions && (
                <>
                  <select className="form-control" name={dataRowName}>
                    <option
                      onClick={() => {
                        setInfoData("")
                      }}
                    >
                      Please select
                    </option>
                    {customFieldOptions.split(",").map(option => (
                      <option
                        key={`select_option_${option.trim()}`}
                        onClick={() => {
                          setInfoData(option.trim())
                        }}
                      >
                        {option.trim()}
                      </option>
                    ))}
                  </select>
                </>
              )}

              {infoType === CUSTOM_NUMBER && (
                <input
                  type={"number"}
                  className="form-control"
                  name={dataRowName}
                  onChange={e => {
                    setInfoData(e.target.value)
                  }}
                />
              )}

              {infoType === CUSTOM_URL && (
                <input
                  type={"url"}
                  className="form-control"
                  name={dataRowName}
                  placeholder="https://"
                  onChange={e => {
                    setInfoData(e.target.value)
                  }}
                />
              )}

              {infoType === STAR_RATTING && (
                <StarRatting
                  defaultRatting={defaultValue}
                  ratingName={dataRowName}
                  setInfoData={setInfoData}
                />
              )}

              {infoType === SLIDER_RATTING && (
                <SliderRatting
                  defaultRatting={defaultValue ? defaultValue : 0}
                  ratingName={dataRowName}
                  setInfoData={setInfoData}
                />
              )}

              {infoType === NUMBER_RATTING && (
                <NumberRatting
                  defaultRatting={defaultValue}
                  ratingName={dataRowName}
                  setInfoData={setInfoData}
                />
              )}
            </React.Fragment>
          )}

          {!customFieldId && (
            <>
              {CONTACT_META_DROPDOWN_FIELDS.indexOf(infoType) !== -1 && (
                <select className="form-select" aria-label="Default select example" name={dataRowName}>
                  <option value={""} onClick={() => setInfoData(null)}>
                    == INDUSTRY ==
                  </option>
                  {industryData.length > 0 && (
                    <>
                      {industryData.map(industry => {
                        let selected = industry.name === infoData ? "selected" : ""
                        return (
                          <option
                            value={industry.name}
                            selected={selected}
                            onClick={() => setInfoData(industry.name)}
                          >
                            {industry.name}
                          </option>
                        )
                      })}
                    </>
                  )}
                </select>
              )}

              {CONTACT_META_DATE_FIELDS.indexOf(infoType) !== -1 && (
                <>
                  <DatePicker selected={infoData} onChange={date => setInfoData(date)} />
                </>
              )}

              {CONTACT_META_TEXTAREA_FIELDS.indexOf(infoType) !== -1 && (
                <textarea
                  className="form-control"
                  name={dataRowName}
                  style={{ height: "100px" }}
                  onChange={e => {
                    setInfoData(e.target.value)
                  }}
                />
              )}

              {CONTACT_META_DROPDOWN_FIELDS.indexOf(infoType) === -1 &&
                CONTACT_META_DATE_FIELDS.indexOf(infoType) === -1 &&
                CONTACT_META_TEXTAREA_FIELDS.indexOf(infoType) === -1 && (
                  <input
                    type="text"
                    className="form-control CD_input_info"
                    value={infoData}
                    defaultValue={infoData}
                    name={dataRowName}
                    placeholder={dataRowInputPlaceHolder}
                    onChange={e => {
                      setInfoData(e.target.value)
                    }}
                    onMouseLeave={e => {
                      setInfoData(e.target.value)
                    }}
                  />
                )}

              {dataRowError && <span className="error-text">{dataRowError}</span>}
            </>
          )}
        </div>

        <div className="col-sm-1 col-xs-12 position-relative CD_Action">
          <a
            className="btnCDEdit"
            data-bs-toggle="tooltip"
            data-placement="top"
            aria-label="Delete"
            onClick={() => saveRowData()}
          >
            <i className="bi bi-check2" />
          </a>
          <a
            className="btnCDDelete"
            data-bs-toggle="tooltip"
            data-placement="top"
            aria-label="Delete"
            onClick={() => {
              setRemoveDataRow(true)
            }}
          >
            <i className="bi bi-x-lg" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default AddDetailRow
