import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "gatsby"
import TagListView from "../../Tasks/Listing/tagListView.js"
import { useState } from "react"

const TransactionStatus = ({ contactTaskData }) => {
  const [transactionTypeIcon, setTransactionTypeIcon] = useState("check-square"),
    [transactionTypeIconColor, setTransactionTypeIconColor] = useState("#fff"),
    [transactionTypeIconBgColor, setTransactionTypeIconBgColor] = useState("#1658b8")

  return (
    <div className="CD_Info_Row border-bottom p-3">
      <div className="col-lg-12">
        <div className="customerJourneyContainer">
          {contactTaskData?.map((contactTaskValue, contactTaskIndex) => {
            return (
              <div key={contactTaskIndex}>
                <div className="row mb-3 align-items-end activeWorkroomContainer">
                  <div className="col-sm-5 col-xs-12 col mb-2 mb-sm-0 text-center text-sm-end">
                    <div className="SwiftCloudWorkroomTags">
                      <ul>
                        <li>
                          <Link
                            to={`/jobs/detail/${contactTaskValue.task.id}`}
                            className="nav-link active goBack "
                            id="PrioritizedListView-tab"
                          >
                            <span
                              className="SwiftCloudWorkroomTagIcon"
                              style={{
                                backgroundColor: contactTaskValue.task.transactionType?.iconBackgroundColor
                                  ? contactTaskValue.task.transactionType?.iconBackgroundColor
                                  : transactionTypeIconBgColor,
                                color: contactTaskValue.task.transactionType?.iconColor
                                  ? contactTaskValue.task.transactionType?.iconColor
                                  : transactionTypeIconColor,
                              }}
                            >
                              <i
                                className={`bi bi-${
                                  contactTaskValue.task.transactionType?.icon
                                    ? contactTaskValue.task.transactionType?.icon
                                    : transactionTypeIcon
                                }`}
                              />
                            </span>{" "}
                            <span className="SwiftCloudWorkroomTagName">
                              {contactTaskValue.task.title}{" "}
                              {contactTaskValue.settingRole?.role
                                ? `(${contactTaskValue.settingRole?.role})`
                                : ""}
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-7 col-xs-12 position-relative">
                    <div className="activeWorkroomStatusBar">
                      <ul className="workflow-wizard">
                        {contactTaskValue.task.metaJson.workflow_status?.map(
                          (workFlowValue, workFlowIndex) => {
                            const matchingStatus = contactTaskValue.task.transactionType?.statuses.find(
                              transactionTypeValue => transactionTypeValue.id === workFlowValue.id
                            )
                            const transactionLabel = matchingStatus ? matchingStatus.label : ""
                            const transactionIcon = matchingStatus ? matchingStatus.icon : ""

                            return (
                              <OverlayTrigger
                                key={workFlowIndex}
                                overlay={
                                  <Tooltip>
                                    {workFlowValue.id === "trophy" ? "Trophy" : transactionLabel}
                                  </Tooltip>
                                }
                              >
                                <li className={`SaleStatus_${workFlowValue.status}`}>
                                  <i
                                    className={`bi bi-${
                                      workFlowValue.id === "trophy" ? "trophy" : transactionIcon
                                    }`}
                                  />
                                </li>
                              </OverlayTrigger>
                            )
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {contactTaskValue.task.tags?.length > 0 && (
                  <div className="row align-items-center mb-4">
                    <div className="col-sm-12 text-center mb-2 mb-sm-0">
                      <div className="SwiftCloudTableTags">
                        <TagListView tags={contactTaskValue.task?.tags} taskId={contactTaskValue.task?.id} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
          <div className="addNewCustomerJourneyContainer" />
        </div>
      </div>
    </div>
  )
}

export default TransactionStatus
