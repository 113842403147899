import * as React from "react"
import { useEffect, useState } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"

const JobTitle = ({ contactId, jobTitle, setJobTitle }) => {
  const fieldName = "contact_job_title_input",
    [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(jobTitle),
    [oldInfoData, setOldInfoData] = useState(jobTitle)

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setInfoData(oldInfoData)
    setSaveOption(false)
  }

  const updateRowData = e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    return new Promise(async () => {
      await AxiosInstance.post(`/contact/update-job-title/${contactId}`, {
        value: formData.get(fieldName),
      })
        .then(function (response) {
          if (response.status === 200) {
            setJobTitle(formData.get(fieldName))
            setInfoData(formData.get(fieldName))
            setOldInfoData(formData.get(fieldName))
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  const deleteJobTitle = e => {
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/update-job-title/${contactId}`, {
        value: "",
      })
        .then(function (response) {
          if (response.status === 200) {
            setJobTitle("")
            setInfoData("")
            setOldInfoData("")
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      hideSaveRowOptions()
    }
  })

  return (
    <React.Fragment>
      <Form onSubmit={updateRowData} style={{ marginBottom: 0 }}>
        <div className="CD_Info_Row border-bottom p-3">
          <div className="row align-items-center">
            <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">Job Title</div>

            <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
              <OverlayTrigger placement="top" overlay={<Tooltip>Private Information</Tooltip>}>
                <span>
                  <i className="bi bi-mortarboard-fill" />
                </span>
              </OverlayTrigger>
            </div>

            <div className="col-sm-6 col-xs-12">
              {!saveOption && <div> {jobTitle} </div>}
              {saveOption && (
                <input
                  className="form-control"
                  name={fieldName}
                  defaultValue={infoData}
                  onClick={e => {
                    setInfoData(e.target.value)
                  }}
                />
              )}
            </div>

            <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
              {saveOption && (
                <React.Fragment>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Save</Tooltip>}>
                    <a className="btnCDEdit" type={"submit"}>
                      <i className="bi bi-check2" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>}>
                    <a className="btnCDDelete" onClick={hideSaveRowOptions}>
                      <i className="bi bi-x-lg" />
                    </a>
                  </OverlayTrigger>
                </React.Fragment>
              )}
              {!saveOption && (
                <>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Search</Tooltip>}>
                    <a
                      className="btnCDSearch"
                      href={`https://www.google.com/search?q=${encodeURIComponent(jobTitle)}`}
                      target="_blank"
                    >
                      <i className="bi bi-search" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                    <a className="btnCDEdit" onClick={showSaveRowOptions}>
                      <i className="bi bi-pencil-fill" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>}>
                    <a className="btnCDDelete" onClick={() => deleteJobTitle()}>
                      <i className="bi bi-trash-fill" />
                    </a>
                  </OverlayTrigger>
                </>
              )}
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default JobTitle
