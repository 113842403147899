import * as React from "react"
import { useEffect, useState } from "react"
import {
  CONTACT_ADDRESS_OPTIONS,
  getTypeData,
  PRIVACY_PRIVATE,
  PRIVACY_PUBLIC,
} from "../../../services/globalStaticData"
import _uniqueId from "lodash/uniqueId"
import {
  MAP_ICON,
  MAP_TYPE,
  mapLabel,
  POSTAL_MAIL_ICON,
  POSTAL_MAIL_TYPE,
  postalMailLabel,
  SMS_ICON,
  SMS_TYPE,
  smsLabel,
} from "./HistoryNote/historyNoteHelper"
import AxiosInstance from "../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const Addresses = ({
  contactId,
  addressData,
  setAddresses,
  getContactData,
  setHistoryType,
  setHistoryLabel,
  setHistoryIcon,
  setHistoryValue,
}) => {
  const [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(addressData.address),
    [oldInfoData, setOldInfoData] = useState(addressData.address),
    [dataPrivacy, setDataPrivacy] = useState(addressData.permission),
    [oldDataPrivacy, setOldDataPrivacy] = useState(addressData.permission)

  let addressFieldName = "contact_address_fields_" + _uniqueId()
  let addressTypeData = getTypeData(CONTACT_ADDRESS_OPTIONS, addressData.type)
  let iconClasses = addressTypeData.icon
  let privacyLabel = "Public"

  if (dataPrivacy === PRIVACY_PRIVATE) {
    iconClasses = iconClasses + " privateInfo"
    privacyLabel = "Private"
  }

  const updatePrivacyInfo = () => {
    setDataPrivacy(dataPrivacy === PRIVACY_PRIVATE ? PRIVACY_PUBLIC : PRIVACY_PRIVATE)
    showSaveRowOptions()
  }

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setInfoData(oldInfoData)
    setDataPrivacy(oldDataPrivacy)
    setSaveOption(false)
  }

  const updateRowData = () => {
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/address/edit/${addressData.id}`, {
        type: addressData.type,
        permission: dataPrivacy,
        value: infoData,
      })
        .then(function (response) {
          if (response.status === 200) {
            setOldDataPrivacy(dataPrivacy)
            setOldInfoData(infoData)
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  const deleteAddress = addressId => {
    if (addressId) {
      return new Promise(async () => {
        await AxiosInstance.delete(`/contact/address/delete/${addressId}`)
          .then(function (response) {
            if (response.status === 200) {
              setUpdateContact(true)
            }
          })
          .catch(function (error) {
            alert("There is some error Please try after sometime.")
          })
      })
    }
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      setAddresses([])
      getContactData(contactId)
      hideSaveRowOptions()
    }
  })
  return (
    <React.Fragment key={`contact_detail_address_${addressData.id}`}>
      {addressTypeData && addressData.address && (
        <div className="CD_Info_Row border-bottom p-3">
          <div className="row align-items-center">
            <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">{addressTypeData.label}</div>
            <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
              <OverlayTrigger placement="top" overlay={<Tooltip>{`${privacyLabel} Information`}</Tooltip>}>
                <a onClick={updatePrivacyInfo}>
                  <i className={iconClasses} />
                </a>
              </OverlayTrigger>
            </div>

            {!saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">
                  <span>{addressData.address}&nbsp;</span>

                  <div className="showOnHover d-inline-block">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Mail</Tooltip>}>
                      <a
                        className="btn btn-secondary btnQuickAction"
                        onClick={() => {
                          setHistoryType(POSTAL_MAIL_TYPE)
                          setHistoryIcon(POSTAL_MAIL_ICON)
                          setHistoryLabel(postalMailLabel(addressData.address))
                          setHistoryValue(addressData.address)
                        }}
                      >
                        <i className="bi bi-envelope-fill" />
                      </a>
                    </OverlayTrigger>
                    &nbsp;
                    <OverlayTrigger placement="top" overlay={<Tooltip>Visit</Tooltip>}>
                      <a
                        className="btn btn-secondary btnQuickAction"
                        onClick={() => {
                          setHistoryType(MAP_TYPE)
                          setHistoryIcon(MAP_ICON)
                          setHistoryLabel(mapLabel(addressData.address))
                          setHistoryValue(addressData.address)
                        }}
                      >
                        <i className="bi bi-geo-alt-fill" />
                      </a>
                    </OverlayTrigger>
                    &nbsp;
                  </div>
                </div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Search</Tooltip>}>
                    <a
                      className="btnCDSearch"
                      href={`https://www.google.com/search?q=${encodeURIComponent(addressData.address)}`}
                      target="_blank"
                    >
                      <i className="bi bi-search" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                    <a className="btnCDEdit" onClick={showSaveRowOptions}>
                      <i className="bi bi-pencil-fill" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                    <a className="btnCDDelete" onClick={() => deleteAddress(addressData.id)}>
                      <i className="bi bi-trash-fill" />
                    </a>
                  </OverlayTrigger>
                </div>
              </>
            )}

            {saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">
                  <textarea
                    className="form-control"
                    style={{ height: "100px" }}
                    value={infoData}
                    defaultValue={infoData}
                    name={addressFieldName}
                    placeholder={addressTypeData.placeholder}
                    onChange={e => {
                      setInfoData(e.target.value)
                    }}
                    onMouseLeave={e => {
                      setInfoData(e.target.value)
                    }}
                  />
                </div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Save</Tooltip>}>
                    <a className="btnCDEdit" onClick={updateRowData}>
                      <i className="bi bi-check2" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>}>
                    <a className="btnCDDelete" onClick={hideSaveRowOptions}>
                      <i className="bi bi-x-lg" />
                    </a>
                  </OverlayTrigger>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Addresses
