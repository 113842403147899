export const NUMBER_RATTING_OPTIONS = [
  {
    index: 0,
    className: "full stars-0",
  },
  {
    index: 1,
    className: "half stars-0-5",
  },
  {
    index: 2,
    className: "full stars-1",
  },
  {
    index: 3,
    className: "half stars-1-5",
  },
  {
    index: 4,
    className: "full stars-2",
  },
  {
    index: 5,
    className: "half stars-2-5",
  },
  {
    index: 6,
    className: "full stars-3",
  },
  {
    index: 7,
    className: "half stars-3-5",
  },
  {
    index: 8,
    className: "full stars-4",
  },
  {
    index: 9,
    className: "half stars-4-5",
  },
  {
    index: 10,
    className: "full stars-5",
  },
]
