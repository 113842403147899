import * as React from "react"
import { useEffect, useState } from "react"
import {
  CONTACT_META_FIELD_OPTIONS,
  getTypeData,
  PRIVACY_PRIVATE,
  PRIVACY_PUBLIC,
} from "../../../services/globalStaticData"
import DatePicker from "react-datepicker"
import AxiosInstance from "../../../services/axiosInstance"

const DateMetaData = ({ contactId, data, setMetaData, getContactData }) => {
  const [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(new Date(data.value)),
    [oldInfoData, setOldInfoData] = useState(new Date(data.value)),
    [dataPrivacy, setDataPrivacy] = useState(data.permission),
    [oldDataPrivacy, setOldDataPrivacy] = useState(data.permission)

  let metaDataType = getTypeData(CONTACT_META_FIELD_OPTIONS, data.type)
  let iconClasses = metaDataType.icon
  let privacyLabel = "Public"

  if (dataPrivacy === PRIVACY_PRIVATE) {
    iconClasses = iconClasses + " privateInfo"
    privacyLabel = "Private"
  }

  const updatePrivacyInfo = () => {
    setDataPrivacy(dataPrivacy === PRIVACY_PRIVATE ? PRIVACY_PUBLIC : PRIVACY_PRIVATE)
    showSaveRowOptions()
  }

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setInfoData(oldInfoData)
    setDataPrivacy(oldDataPrivacy)
    setSaveOption(false)
  }

  const updateRowData = () => {
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/meta-data/edit/${data.id}`, {
        type: data.type,
        permission: dataPrivacy,
        value: infoData,
      })
        .then(function (response) {
          if (response.status === 200) {
            setOldDataPrivacy(dataPrivacy)
            setOldInfoData(infoData)
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  const deleteLink = metaDataId => {
    if (metaDataId) {
      return new Promise(async () => {
        await AxiosInstance.delete(`/contact/meta-data/delete/${metaDataId}`)
          .then(function (response) {
            if (response.status === 200) {
              setUpdateContact(true)
            }
          })
          .catch(function (error) {
            if (error.response.status === 400) {
              // setNotesTextAreaError(error.response.data.message)
            } else {
              // setNotesTextAreaError(error.response.data.detail)
            }
          })
      })
    }
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      setMetaData([])
      getContactData(contactId)
      hideSaveRowOptions()
    }
  })
  return (
    <React.Fragment key={`contact_detail_meta_data_${data.id}`}>
      {metaDataType && data.value && (
        <div className="CD_Info_Row border-bottom p-3">
          <div className="row align-items-center">
            <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">{metaDataType.label}</div>
            <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
              <a
                data-bs-toggle="tooltip"
                data-placement="top"
                title={`${privacyLabel} Information`}
                onClick={updatePrivacyInfo}
              >
                <i className={iconClasses} />
              </a>
            </div>

            <div className="col-sm-6 col-xs-12">
              <DatePicker
                selected={infoData}
                onChange={date => {
                  setSaveOption(true)
                  setInfoData(date)
                }}
              />
            </div>

            {!saveOption && (
              <>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <a
                    className="btnCDDelete"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    title="Delete"
                    onClick={() => deleteLink(data.id)}
                  >
                    <i className="bi bi-trash-fill" />
                  </a>
                </div>
              </>
            )}

            {saveOption && (
              <>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <a
                    className="btnCDEdit"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    aria-label="Save"
                    onClick={updateRowData}
                  >
                    <i className="bi bi-check2" />
                  </a>
                  <a
                    className="btnCDDelete"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    title="Cancel"
                    onClick={hideSaveRowOptions}
                  >
                    <i className="bi bi-x-lg" />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default DateMetaData
