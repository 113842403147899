import * as React from "react"
import {
  CONTACT_EMAIL_OPTIONS,
  getTypeData,
  PRIVACY_PRIVATE,
  PRIVACY_PUBLIC,
} from "../../../services/globalStaticData"
import {
  EMAIL_ICON,
  EMAIL_TYPE,
  emailLabel,
  PHONE_ICON,
  PHONE_TYPE,
  phoneLabel,
  SMS_ICON,
  SMS_TYPE,
  smsLabel,
} from "./HistoryNote/historyNoteHelper"
import { useEffect, useState } from "react"
import _uniqueId from "lodash/uniqueId"
import validator from "validator"
import AxiosInstance from "../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const Emails = ({
  contactId,
  emailData,
  setEmails,
  getContactData,
  setHistoryType,
  setHistoryLabel,
  setHistoryIcon,
  setHistoryValue,
}) => {
  const [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(emailData.email),
    [oldInfoData, setOldInfoData] = useState(emailData.email),
    [dataPrivacy, setDataPrivacy] = useState(emailData.permission),
    [oldDataPrivacy, setOldDataPrivacy] = useState(emailData.permission)

  let emailTypeData = getTypeData(CONTACT_EMAIL_OPTIONS, emailData.type)
  let emailFieldName = "contact_email_fields_" + _uniqueId()
  let iconClasses = emailTypeData.icon
  let privacyLabel = "Public"

  if (dataPrivacy === PRIVACY_PRIVATE) {
    iconClasses = iconClasses + " privateInfo"
    privacyLabel = "Private"
  }

  const updatePrivacyInfo = () => {
    setDataPrivacy(dataPrivacy === PRIVACY_PRIVATE ? PRIVACY_PUBLIC : PRIVACY_PRIVATE)
    showSaveRowOptions()
  }

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setInfoData(oldInfoData)
    setDataPrivacy(oldDataPrivacy)
    setSaveOption(false)
  }

  const updateRowData = () => {
    if (!validator.isEmail(infoData)) {
      alert("Please Enter Valida Email address.")
      return false
    } else {
      return new Promise(async () => {
        await AxiosInstance.post(`/contact/email/edit/${emailData.id}`, {
          type: emailData.type,
          permission: dataPrivacy,
          value: infoData,
        })
          .then(function (response) {
            if (response.status === 200) {
              setOldDataPrivacy(dataPrivacy)
              setOldInfoData(infoData)
              hideSaveRowOptions()
              setUpdateContact(true)
            }
          })
          .catch(function (error) {
            alert("There is some error Please try after sometime.")
          })
      })
    }
  }

  const deleteEmail = emailId => {
    if (emailId) {
      return new Promise(async () => {
        await AxiosInstance.delete(`/contact/email/delete/${emailId}`)
          .then(function (response) {
            if (response.status === 200) {
              setUpdateContact(true)
            }
          })
          .catch(function (error) {
            alert("There is some error Please try after sometime.")
          })
      })
    }
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      setEmails([])
      getContactData(contactId)
      hideSaveRowOptions()
    }
  })

  return (
    <React.Fragment key={`contact_detail_emails_${emailData.id}`}>
      {emailTypeData && emailData.email && (
        <div className="CD_Info_Row border-bottom p-3">
          <div className="row align-items-center">
            <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">{emailTypeData.label}</div>
            <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
              <OverlayTrigger placement="top" overlay={<Tooltip>Private Information</Tooltip>}>
                <span>
                  <i className={iconClasses} />
                </span>
              </OverlayTrigger>
            </div>

            {!saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">
                  <a
                    className="email_deliverable btnOpenChatDock"
                    title={`${emailData.email} - deliverable`}
                    onClick={() => {
                      setHistoryType(EMAIL_TYPE)
                      setHistoryIcon(EMAIL_ICON)
                      setHistoryLabel(emailLabel(emailData.email))
                      setHistoryValue(emailData.email)
                    }}
                  >
                    {emailData.email} <i className="bi bi-check-lg" />
                  </a>
                </div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Search</Tooltip>}>
                    <a
                      className="btnCDSearch"
                      href={`https://www.google.com/search?q=${encodeURIComponent(emailData.email)}`}
                      target="_blank"
                    >
                      <i className="bi bi-search" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                    <a className="btnCDEdit" onClick={showSaveRowOptions}>
                      <i className="bi bi-pencil-fill" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                    <a className="btnCDDelete" onClick={() => deleteEmail(emailData.id)}>
                      <i className="bi bi-trash-fill" />
                    </a>
                  </OverlayTrigger>
                </div>
              </>
            )}

            {saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">
                  <input
                    type="text"
                    className="form-control CD_input_info"
                    value={infoData}
                    defaultValue={infoData}
                    name={emailFieldName}
                    placeholder={emailTypeData.placeholder}
                    onChange={e => {
                      setInfoData(e.target.value)
                    }}
                    onMouseLeave={e => {
                      setInfoData(e.target.value)
                    }}
                  />
                </div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Save</Tooltip>}>
                    <a className="btnCDEdit" onClick={updateRowData}>
                      <i className="bi bi-check2" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>}>
                    <a className="btnCDDelete" onClick={hideSaveRowOptions}>
                      <i className="bi bi-x-lg" />
                    </a>
                  </OverlayTrigger>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Emails
