import React, { useEffect, useRef, useState } from "react"
import { Modal, Button, Form } from "react-bootstrap"
import { CONTACT_TYPE_COMPANY, CONTACT_TYPE_HUMAN } from "../contactHelper"
import AxiosInstance from "../../../services/axiosInstance"
import { getLoginUserId } from "../../../services/auth"

function AddSubContact({
  contactType,
  contactId,
  setSubContactData,
  selectedContactId,
  setModalShow,
  setLinkModal,
  modalShow,
  linkModal,
}) {
  const [subContactJobtitle, setSubContactJobtitle] = useState("")
  const [subContactInput, setSubContactInput] = useState({})
  const [subContactType, setSubContactType] = useState(contactType)
  const [selectedContactType, setSelectedContactType] = useState("")
  const [uploadedFile, setUploadedFile] = useState(null),
    [uploadedFilePath, setUploadedFilePath] = useState("StaticImages/avatar.gif"),
    [uploadFile, setUploadFile] = useState(false)

  const handleModalClose = () => {
    setModalShow(false)
  }

  const handleLinkModalClose = () => {
    setLinkModal(false)
  }
  const handleSaveChanges = () => {
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/add`, {
        name: subContactInput.subContactName,
        parent: contactId,
        profilePic: uploadedFile,
        contactType: selectedContactType,
        email: subContactInput.subContactEmail,
        jobRole: subContactInput.subContactRole,
        jobTitle: subContactJobtitle,
        phone: {
          type: "phone_mobile",
          permission: "private",
          phone: subContactInput.subContactPhone == null ? "" : subContactInput.subContactPhone,
        },
      })
        .then(responce => {
          if (responce.status == 201) {
            AxiosInstance.get(`/contact/detail/${selectedContactId}`).then(response => {
              setSubContactData(response.data.contact.subContacts)
            })
            handleModalClose()
            setSubContactInput({})
            setSubContactJobtitle("")
            setUploadedFilePath("")
            setUploadedFile("")
          }
        })
        .catch(error => {
          console.log(error)
        })
    })
  }
  const handleSubContactInputsChange = event => {
    const name = event.target.name
    const value = event.target.value
    setSubContactInput(values => ({ ...values, [name]: value }))
  }

  const handleSubContactJobTitle = event => {
    setSubContactJobtitle(event.target.value)
  }
  const handleContactType = event => {
    setSubContactType(event.target.value)

    if (event.target.value === CONTACT_TYPE_HUMAN) {
      setSelectedContactType(CONTACT_TYPE_HUMAN)
    } else {
      setSelectedContactType(CONTACT_TYPE_COMPANY)
    }
  }

  const onImageChange = e => {
    setUploadedFile(e.target.files[0])
    setUploadFile(true)
  }

  const handleUpload = () => {
    let formData = new FormData()
    formData.append("ProfilePic", uploadedFile)
    formData.append("user_id", getLoginUserId())
    formData.append("parent_id", "0")
    AxiosInstance.post(`/settings/general-setting/upload-file-r2`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          setUploadedFilePath(response.data.profilePic.filePath)
          setUploadedFile(response.data.profilePic.filePath)
        }
      })
      .catch(function (error) {})
  }

  const contactImage = useRef(null)
  const openSelectImageBox = () => {
    contactImage.current.click()
  }

  useEffect(() => {
    setSelectedContactType(contactType)

    if (uploadFile) {
      setUploadFile(false)
      handleUpload()
    }
  }, [contactType, uploadFile])

  return (
    <>
      <Modal
        show={modalShow}
        onHide={handleModalClose}
        id="addNewSubContactModal"
        aria-labelledby="addNewSubContactModalLabel"
      >
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5" id="addNewSubContactModalLabel">
            <i className="bi bi-person-fill-add mx-2"></i>
            Add New Sub Contact
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 row align-items-center">
            <div className="col-lg-12 text-center">
              <div className="SubContactAvatar">
                <input
                  type="file"
                  accept="image/*"
                  className="subContactAvatarFile"
                  style={{ visibility: "hidden", position: "absolute" }}
                />
                <a
                  href="#"
                  className="btn btn-default btnEditSubContactAvatar"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Select avatar"
                  aria-label="Select avatar"
                  onClick={openSelectImageBox}
                >
                  <i className="bi bi-pencil-fill" />
                </a>
                <img src={`${process.env.GATSBY_WRANGLER_URL}${uploadedFilePath}`} alt="" />
                <input type="file" ref={contactImage} onChange={onImageChange} hidden />
              </div>
            </div>
          </div>
          <div className="mb-3 row align-items-center">
            <label className="col-sm-3 col-form-label text-end">Contact Type</label>
            <div className="col-sm-9">
              <Form.Select
                defaultValue={contactType}
                onChange={handleContactType}
                aria-label="Default select example"
              >
                <option value={CONTACT_TYPE_HUMAN}>Human</option>
                <option value={CONTACT_TYPE_COMPANY}>Company</option>
              </Form.Select>
            </div>
          </div>
          <div className="mb-3 row align-items-center">
            <label className="col-sm-3 col-form-label text-end">Name</label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                value={subContactInput.subContactName}
                name="subContactName"
                onChange={handleSubContactInputsChange}
              />
            </div>
          </div>
          <div className="mb-3 row align-items-center">
            <label className="col-sm-3 col-form-label text-end">
              {selectedContactType === CONTACT_TYPE_HUMAN ? "Role" : "Job Title"}
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name="subContactRole"
                value={
                  selectedContactType === CONTACT_TYPE_HUMAN
                    ? subContactInput.subContactRole
                    : subContactJobtitle
                }
                className="form-control"
                onChange={
                  selectedContactType === CONTACT_TYPE_HUMAN
                    ? handleSubContactInputsChange
                    : handleSubContactJobTitle
                }
              />
            </div>
          </div>
          <div className="mb-3 row align-items-center">
            <label className="col-sm-3 col-form-label text-end">Email</label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                value={subContactInput.subContactEmail}
                name="subContactEmail"
                onChange={handleSubContactInputsChange}
              />
            </div>
          </div>
          <div className="mb-3 row align-items-center">
            <label className="col-sm-3 col-form-label text-end">Phone</label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                value={subContactInput.subContactPhone}
                name="subContactPhone"
                onChange={handleSubContactInputsChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-default" onClick={handleModalClose}>
            <i className="bi bi-x-lg"></i>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            <i className="bi bi-check2"></i>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        id="linkedContactModal"
        show={linkModal}
        onHide={handleLinkModalClose}
        aria-labelledby="linkedContactModalTitle"
        dialogClassName="modal-lg modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="linkedContactModalTitle">
            <i className="bi bi-link-45deg"></i>
            &nbsp;Linked Contact
          </h5>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="mt-3 row align-items-center shareWithOtherPeopleContainer">
            <div className="col-md-12">
              <ul className="sharedWithPeople">
                <li>
                  <div className="sharedWithPeopleInfoBox">
                    <img
                      src={process.env.GATSBY_WRANGLER_URL + "StaticImages/avatar.gif"}
                      className="userAvatar"
                    />
                    <div className="userInfo">
                      <div className="userRealName">
                        <a href="#">
                          <strong>Northrop Grumman</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="mt-3 text-center">
                <button
                  className="btnAddNewGreenDashed btnAddNewPeople"
                  data-bs-toggle="tooltip"
                  data-bs-title="Link More Contacts"
                  data-bs-original-title=""
                  title="Link More Contacts"
                >
                  <i className="bi bi-plus-lg"></i>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <div className="row g-0 align-items-center">
            <div className="col-sm-4"> </div>
            <div className="col-sm-8 text-end">
              <button type="button" className="btn btn-primary" onClick={handleLinkModalClose}>
                <i className="bi bi-check-lg"></i>
                Save
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddSubContact
