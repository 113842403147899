import * as React from "react"
import "rc-slider/assets/index.css"
import _uniqueId from "lodash/uniqueId"
import { useEffect, useState } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { PRIVACY_PRIVATE, PRIVACY_PUBLIC } from "../../../../services/globalStaticData"
import Slider from "rc-slider"
import { CUSTOM_ADDRESS } from "../../contactHelper"
import {
  MAP_ICON,
  MAP_TYPE,
  mapLabel,
  POSTAL_MAIL_ICON,
  POSTAL_MAIL_TYPE,
  postalMailLabel,
} from "../HistoryNote/historyNoteHelper"
import AxiosInstance from "../../../../services/axiosInstance"

const CustomFieldOptions = ({
  contactId,
  customField,
  getContactData,
  setCustomFields,
  setHistoryType,
  setHistoryLabel,
  setHistoryIcon,
  setHistoryValue,
}) => {
  const [updateContact, setUpdateContact] = useState(false),
    [saveOption, setSaveOption] = useState(false),
    [infoData, setInfoData] = useState(customField.data),
    [oldInfoData, setOldInfoData] = useState(customField.data),
    [dataPrivacy, setDataPrivacy] = useState(customField.permission),
    [oldDataPrivacy, setOldDataPrivacy] = useState(customField.permission)

  let customFieldName = "contact_custom_fields_" + _uniqueId()
  let privacyIcon = customField.settingCustomField.icon
  let privacyLabel = "Public"

  if (dataPrivacy === PRIVACY_PRIVATE) {
    privacyIcon = customField.settingCustomField.icon + " privateInfo"
    privacyLabel = "Private"
  }

  const updatePrivacyInfo = () => {
    setDataPrivacy(dataPrivacy === PRIVACY_PRIVATE ? PRIVACY_PUBLIC : PRIVACY_PRIVATE)
    showSaveRowOptions()
  }

  const showSaveRowOptions = () => {
    setSaveOption(true)
  }

  const hideSaveRowOptions = () => {
    setInfoData(oldInfoData)
    setDataPrivacy(oldDataPrivacy)
    setSaveOption(false)
  }

  const updateRowData = e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    return new Promise(async () => {
      await AxiosInstance.post(`/contact/custom-field/edit/${customField.id}`, {
        type: customField.type,
        permission: dataPrivacy,
        value: formData.get(customFieldName),
        settingCustomFieldId: customField.settingCustomField.id,
      })
        .then(function (response) {
          if (response.status === 200) {
            setOldDataPrivacy(dataPrivacy)
            setOldInfoData(infoData)
            hideSaveRowOptions()
            setUpdateContact(true)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  const deleteCustomField = customFieldId => {
    if (customFieldId) {
      return new Promise(async () => {
        await AxiosInstance.delete(`/contact/custom-field/delete/${customFieldId}`)
          .then(function (response) {
            if (response.status === 200) {
              setUpdateContact(true)
            }
          })
          .catch(function (error) {
            alert("there is some error please try again after some time.")
          })
      })
    }
  }

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false)
      setCustomFields([])
      getContactData(contactId)
      hideSaveRowOptions()
    }
  })

  return (
    <React.Fragment>
      <Form onSubmit={updateRowData}>
        <div className="CD_Info_Row border-bottom p-3" key={customFieldName}>
          <div className="row align-items-center">
            <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">
              {customField.settingCustomField.label}
            </div>

            <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
              <a
                data-bs-toggle="tooltip"
                data-placement="top"
                title={`${privacyLabel} Information`}
                onClick={updatePrivacyInfo}
              >
                <i className={privacyIcon} />
              </a>
            </div>

            {!saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">{oldInfoData}&nbsp;</div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Search</Tooltip>}>
                    <a
                      className="btnCDSearch"
                      href={`https://www.google.com/search?q=${encodeURIComponent(infoData)}`}
                      target="_blank"
                    >
                      <i className="bi bi-search" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                    <a className="btnCDEdit" onClick={showSaveRowOptions}>
                      <i className="bi bi-pencil-fill" />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                    <a className="btnCDDelete" onClick={() => deleteCustomField(customField.id)}>
                      <i className="bi bi-trash-fill" />
                    </a>
                  </OverlayTrigger>
                </div>
              </>
            )}
            {saveOption && (
              <>
                <div className="col-sm-6 col-xs-12">
                  <select className="form-control" name={customFieldName} value={infoData}>
                    <option
                      onClick={() => {
                        setInfoData("")
                      }}
                    >
                      Please select
                    </option>
                    {customField.settingCustomField.defaultValue.split(",").map(option => (
                      <option
                        key={`select_option_${option.trim()}`}
                        onClick={() => {
                          setInfoData(option.trim())
                        }}
                      >
                        {option.trim()}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                  <a
                    className="btnCDEdit"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    aria-label="Save"
                    type={"submit"}
                  >
                    <i className="bi bi-check2" />
                  </a>
                  <a
                    className="btnCDDelete"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    title="Cancel"
                    onClick={hideSaveRowOptions}
                  >
                    <i className="bi bi-x-lg" />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default CustomFieldOptions
