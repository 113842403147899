import * as React from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "gatsby"
import moment from "moment"
import { DATE_FORMAT, CURRENT_DATE_TIME } from "../../contactHelper"
import EventModal from "../../../Calendar/eventModal"
import { useState } from "react"

const CalendarPanel = ({
  contactEventData,
  isPastEvent,
  setIsPastEvent,
  selectedContactId,
  selectedContactName,
  selectedContactImage,
}) => {
  const [openEventModel, setOpenEventModel] = useState(false)
  const selectedEventTime = moment().format(CURRENT_DATE_TIME)

  return (
    <div className="col-lg-12 mb-3" id="calendar_panel">
      <Accordion defaultActiveKey="0" id="peoplePanelAccordion">
        <Accordion.Item eventKey="0" className="panel panel-purple">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-calendar-check" />
            </span>
            <span className="panel-heading-title">Calendar</span>
          </Accordion.Header>
          <Accordion.Body id="event_container">
            <div className="row g-0">
              <div className="col tableContainer">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="float-end">
                      <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                        <li className="nav-item">
                          <button
                            type="button"
                            className="btn btn-primary mt-1 me-1"
                            onClick={() => {
                              setOpenEventModel(true)
                            }}
                          >
                            <i className="bi bi-plus" /> Add New
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <OverlayTrigger placement="top" overlay={<Tooltip>Past Events</Tooltip>}>
                            <a
                              className={`nav-link${isPastEvent ? " active" : ""}`}
                              id="past-event-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#past-event-tab-pane"
                              role="tab"
                              aria-controls="past-event-tab-pane"
                              aria-selected="true"
                              onClick={() => {
                                setIsPastEvent(true)
                              }}
                            >
                              <span data-bs-toggle="tooltip">
                                <i className="bi bi-arrow-counterclockwise" />
                              </span>
                            </a>
                          </OverlayTrigger>
                        </li>

                        <li className="nav-item" role="presentation">
                          <OverlayTrigger placement="top" overlay={<Tooltip>Upcoming Events</Tooltip>}>
                            <a
                              className={`nav-link${!isPastEvent ? " active" : ""}`}
                              id="past-event-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#past-event-tab-pane"
                              role="tab"
                              aria-controls="past-event-tab-pane"
                              aria-selected="true"
                              onClick={() => {
                                setIsPastEvent(false)
                              }}
                            >
                              <span data-bs-toggle="tooltip">
                                <i className="bi bi-arrow-clockwise" />
                              </span>
                            </a>
                          </OverlayTrigger>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-12 tableFadeOut">
                    <div className="tab-content autoAdjustSearchBox" id="CalendarTabs">
                      <div
                        className="tab-pane fade active show"
                        id="past-event-tab-pane"
                        role="tabpanel"
                        aria-labelledby="past-event-tab"
                        tabIndex={0}
                      >
                        <div className="table-responsive">
                          <table id="tblPastEvents" className="table dataTable" cellSpacing={0} width="100%">
                            <thead>
                              <tr>
                                <th width="5%" className="nowrap" />
                                <th width="65%" className="nowrap">
                                  Event
                                </th>
                                <th width="30%" className="d-none d-md-table-cell">
                                  Scheduled
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {contactEventData.map((eventValue, eventKey) => {
                                return (
                                  <tr key={eventKey}>
                                    <td className="text-center tableColChkDel">
                                      <div className="tableColChkDelBox">
                                        <input
                                          type="checkbox"
                                          name="fileid[]"
                                          id={`CP_ID${eventValue?.id}`}
                                          className="css-checkbox"
                                        />
                                        <label
                                          htmlFor={`CP_ID${eventValue?.id}`}
                                          className="css-checkbox-label"
                                        />

                                        {eventValue.task ? (
                                          <span
                                            className="tableColChkSpan transactionTypeIconColor"
                                            style={{
                                              color: eventValue.task.transactionType?.iconColor,
                                              backgroundColor:
                                                eventValue.task.transactionType?.iconBackgroundColor,
                                            }}
                                          >
                                            <i
                                              className={`bi bi-${
                                                eventValue.task.transactionType
                                                  ? eventValue.task.transactionType.icon
                                                  : "check-square"
                                              }`}
                                            />
                                          </span>
                                        ) : (
                                          <span className="tableColChkSpan btn-secondary">
                                            <i className="bi bi-calendar3" />
                                          </span>
                                        )}
                                      </div>
                                    </td>

                                    <td>
                                      {eventValue?.task ? (
                                        <Link to={`/jobs/detail/${eventValue.task?.id}`}>
                                          {eventValue?.title}
                                        </Link>
                                      ) : (
                                        <Link to={`/calendar/detail/${eventValue?.id}`}>
                                          {eventValue?.title}
                                        </Link>
                                      )}
                                    </td>
                                    <td className="d-none d-md-table-cell">
                                      {moment(eventValue?.createdAt).format(DATE_FORMAT)}
                                    </td>
                                  </tr>
                                )
                              })}

                              {/* <tr>
                                  <td className="text-center tableColChkDel">
                                    <div className="tableColChkDelBox">
                                      <input
                                        type="checkbox"
                                        name="fileid[]"
                                        id="CP_ID2"
                                        className="css-checkbox"
                                      />
                                      <label
                                        htmlFor="CP_ID2"
                                        className="css-checkbox-label"
                                      />
                                      <span className="tableColChkSpan btn-secondary">
                                        <i className="bi bi-calendar-check" />
                                      </span>
                                    </div>
                                  </td>
                                  <td>Dental Cleaning &amp; Checkup</td>
                                  <td className="d-none d-md-table-cell">
                                    <time
                                      className="timeago"
                                      dateTime="2023-02-01T09:24:17Z"
                                      title="Feb 1, 2023"
                                    >
                                      about a year ago
                                    </time>
                                  </td>
                                </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {openEventModel && (
        <EventModal
          showModal={openEventModel}
          setShowModal={setOpenEventModel}
          selectedEventTime={selectedEventTime}
          selectedContactId={selectedContactId}
          selectedContactName={selectedContactName}
          selectedContactImage={selectedContactImage}
        />
      )}
    </div>
  )
}

export default CalendarPanel
