import React, { useEffect, useRef, useState } from "react"

import { getLoginUserId } from "../../../services/auth"
import AxiosInstance from "../../../services/axiosInstance"

const ContactFileUpload = ({ contactId, getContactData }) => {
  const [uploadedFile, setUploadedFile] = useState(null),
    [uploadFile, setUploadFile] = useState(false)

  const handleSubmit = () => {
    return new Promise(async resolve => {
      await AxiosInstance.post(
        `/contact/upload-profile-pic/${contactId}`,
        {
          user_id: getLoginUserId(),
          profile_pic: uploadedFile,
          parent_id: 0,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(function (response) {
          if (response.status === 200) {
            getContactData(contactId)
          }
          window.location.reload()
          return response.data
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  }

  const contactImage = useRef(null)
  const openSelectImageBox = () => {
    contactImage.current.click()
  }

  const onImageChange = e => {
    setUploadedFile(e.target.files[0])
    setUploadFile(true)
  }

  useEffect(() => {
    if (uploadFile) {
      setUploadFile(false)
      handleSubmit()
    }
  }, [uploadFile])

  return (
    <>
      <a
        href="#"
        className="btn btn-default btnEditAvatar"
        data-bs-toggle="tooltip"
        data-placement="top"
        title="Update Image"
        onClick={openSelectImageBox}
      >
        <i className="bi bi-pencil-fill" />
      </a>
      <input type="file" ref={contactImage} onChange={onImageChange} hidden />
    </>
  )
}

export default ContactFileUpload
