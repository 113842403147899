import * as React from "react"
import moment from "moment"

import {
  ASAP,
  taskStatusesData,
  taskTimeData,
  taskUrgencyData,
  ADD_NEW_CONTACT,
  SEARCH_EXISTING_CONTACTS,
} from "../../Tasks/taskHelper.js"
import AxiosInstance from "../../../services/axiosInstance.js"
import { DATE_FORMAT } from "../../Calendar/eventHelper.js"
import { useEffect, useState } from "react"
import { Modal, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap"
import {
  PRIVACY_PUBLIC,
  DEFAULT_CONTACT_PHONE_OPTION,
  DEFAULT_CONTACT_ADDRESS_OPTION,
} from "../../../services/globalStaticData.js"
import { CONTACT_TYPE_HUMAN } from "../contactHelper.js"
import TaskContactForm from "./taskContactForm.js"

const AddTaskModalForContact = ({
  showModal,
  setShowModal,
  transactionValue,
  setTransactionValue,
  selectedContactId,
  tempContactName,
  profilePic,
  getContactData,
}) => {
  const [isEventDateVisible, setIsEventDateVisible] = useState(false)

  const [status, setStatus] = useState("New"),
    [statusClass, setStatusClass] = useState("new"),
    [statusIcon, setStatusIcon] = useState("app"),
    [estimatedTime, setEstimatedTime] = useState("1 Hour"),
    [estimatedTimeClass, setEstimatedTimeClass] = useState("time_15_min"),
    [urgency, setUrgency] = useState(ASAP),
    [urgencyClass, setUrgencyClass] = useState("asap"),
    [urgencyIcon, setUrgencyIcon] = useState("heart-fill"),
    [projectTypes, setProjectTypes] = useState([]),
    [isShowStatus, setIsShowStatus] = useState(false),
    [isShowUrgency, setIsShowUrgency] = useState(false),
    [isShowEstimatedTime, setIsShowEstimatedTime] = useState(false),
    [taskStatusesNewData, setTaskStatusesNewData] = useState(taskStatusesData),
    [isDefaultStatusData, setIsDefaultStatusData] = useState(true),
    [transactionTypeStatus, setTransactionTypeStatus] = useState(null),
    [isDisplayNewPerson, setIsDisplayNewPerson] = useState(true),
    [settingRole, setSettingRole] = useState([]),
    [defaultSelectedRole, setDefaultSelectedRole] = useState(""),
    [defaultUserOptionSelected, setDefaultUserOptionSelected] = useState(SEARCH_EXISTING_CONTACTS),
    [uploadedFile, setUploadedFile] = useState(null),
    [selectedPhoneType, setSelectedPhoneType] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),
    [selectedAddressType, setSelectedAddressType] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.type),
    [address, setAddress] = useState(""),
    [existingContactId, setExistingContactId] = useState(selectedContactId)

  const eventDateTimePicker = () => {
    setIsEventDateVisible(!isEventDateVisible)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const submitButton = e.nativeEvent.submitter.name

    const title = formData.get("title")
    const eventTime = formData.get("eventTime")
    const transactionType = parseInt(formData.get("scheduler_list"))

    if (title) {
      return new Promise(async (resolve, reject) => {
        try {
          let postData = {
            title,
            status: transactionTypeStatus ? null : status,
            urgency,
            estimatedTime,
            note: "",
            transactionType: transactionType || undefined,
            transactionTypeStatus: transactionTypeStatus || null,
          }

          if (isEventDateVisible) {
            postData.startTime = moment(eventTime).format(DATE_FORMAT)
            postData.endTime = moment(eventTime).add(30, "minutes").format(DATE_FORMAT)
          }

          if (isDisplayNewPerson && defaultUserOptionSelected === ADD_NEW_CONTACT) {
            if (formData.get("contactName") === "") {
              alert("Please enter Contact Name.")
              return false
            }
          }

          if (isDisplayNewPerson && defaultUserOptionSelected === SEARCH_EXISTING_CONTACTS) {
            if (existingContactId === "") {
              alert("Please select existing Contact.")
              return false
            }
          }

          const taskResponse = await AxiosInstance.post(`/task/add`, postData)
          const selectedId = taskResponse.data.task

          if (isDisplayNewPerson) {
            let contactId
            if (defaultUserOptionSelected === ADD_NEW_CONTACT) {
              const contactData = {
                name: formData.get("contactName"),
                email: formData.get("contactEmail"),
                profilePic: uploadedFile,
                companyName: formData.get("companyName"),
                jobTitle: formData.get("jobTitle"),
                contactType: CONTACT_TYPE_HUMAN,
                phone: {
                  type: selectedPhoneType,
                  phone: formData.get("contactPhone"),
                  permission: PRIVACY_PUBLIC,
                },
                address: {
                  type: selectedAddressType,
                  address: address,
                  permission: PRIVACY_PUBLIC,
                },
                metaData: [],
                customFields: [],
              }

              const saveContactResponse = await AxiosInstance.post(`/contact/add`, contactData)
              contactId = saveContactResponse.data.contact
            } else {
              contactId = existingContactId
            }

            await AxiosInstance.post(
              `task/assign-task-contact/${selectedId}/${contactId}/${defaultSelectedRole}`
            )
          }

          if (submitButton === "add-new") {
            setShowModal(true)
            e.target.reset()
          } else {
            getContactData(existingContactId)
            setShowModal(false)
          }
          resolve(taskResponse.data)
        } catch (error) {
          console.error(error)
          reject(error)
        }
      })
    }
  }

  const projectTypesData = () => {
    AxiosInstance.get("/settings/transaction-type")
      .then(response => {
        setProjectTypes(response.data.items)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const showHideTaskDropdown = (activeStatus, taskFor) => {
    if (taskFor == "status") {
      setIsShowStatus(activeStatus)
    } else if (taskFor == "urgency") {
      setIsShowUrgency(activeStatus)
    } else if (taskFor == "estimatedTime") {
      setIsShowEstimatedTime(activeStatus)
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleTransactionChange = event => {
    setTransactionValue(event.target.value)

    const selectedTransactionTypeId = event.target.value
    const selectedOptionDataType = event.target.selectedOptions[0].getAttribute("data-type")

    if (selectedOptionDataType == "other") {
      setIsDefaultStatusData(true)
      setTaskStatusesNewData(taskStatusesData)
      setStatus("New")
      setStatusIcon("app")
      setStatusClass("new")
    } else {
      getTransactionDetail(selectedTransactionTypeId)
    }
  }

  const getTransactionDetail = transactionTypeId => {
    return new Promise(async () => {
      await AxiosInstance.get(`/settings/transaction-type/detail/${transactionTypeId}`).then(function (
        response
      ) {
        if (response.status === 200) {
          const transactionType = response.data.type

          setTaskStatusesNewData(transactionType.statuses)
          setIsDefaultStatusData(false)
          setStatus(transactionType.statuses[0].label)
          setTransactionTypeStatus(transactionType.statuses[0].id)
          setStatusIcon(transactionType.statuses[0].icon)
        }
      })
    })
  }

  const businessRoleTable = () => {
    AxiosInstance.get("/settings/role")
      .then(response => {
        if (response.data.items.length === 0) {
          setSettingRole(null)
        } else {
          setSettingRole(response.data.items)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    businessRoleTable()
    projectTypesData()
    getTransactionDetail(transactionValue)
  }, [])

  return (
    <Modal show={showModal} onHide={handleClose} id="addNewTaskModal">
      <form onSubmit={handleSubmit} method="post">
        <div className="modal-content">
          <div className="modal-header">
            <div className="addNewTaskHeader">
              <div className="fs-4">
                <i className="bi bi-check2-square" /> Add New
              </div>{" "}
              <div className="addNewTaskHeaderType">
                <select
                  className="form-select form-select-lg"
                  name="scheduler_list"
                  id="scheduler_list"
                  value={transactionValue}
                  // defaultValue={transactionValue}
                  onChange={handleTransactionChange}
                >
                  <optgroup label="== Other ==">
                    <option value="Manual" data-type="other">
                      Task
                    </option>
                  </optgroup>
                  <optgroup label="== By Job / Project / Sales Opportunity ==" />

                  {projectTypes.map((projectType, index) => (
                    <option key={index} value={projectType.id}>
                      {projectType.dealTypeLabel}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <input
                name="title"
                id="title"
                placeholder="New Task / Project Title"
                className="form-control"
                type="text"
              />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="btn-group" role="group" id="statusContainer">
                  <button
                    type="button"
                    className={`btn dropdown-toggle statusContainer ${
                      isDefaultStatusData ? `taskStatus_${statusClass}` : "transactionTypeIconColor"
                    }`}
                    data-bs-toggle={isShowStatus ? "dropdown" : ""}
                    aria-expanded="false"
                    onClick={() => showHideTaskDropdown(true, "status")}
                  >
                    <i className={`bi bi-${statusIcon}`} /> {status}
                  </button>
                  <ul
                    className={
                      isShowStatus ? "dropdown-menu hiddenStatus show" : "dropdown-menu hiddenStatus"
                    }
                  >
                    {taskStatusesNewData.map((taskStatus, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setStatus(taskStatus.label)
                          setStatusClass(taskStatus.class)
                          setStatusIcon(taskStatus.icon)
                          setTransactionTypeStatus(taskStatus.id)
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => showHideTaskDropdown(false, "status")}
                        >
                          <span
                            className={`${
                              isDefaultStatusData
                                ? `taskStatus_${taskStatus.class}`
                                : "transactionTypeIconColor"
                            }`}
                          >
                            <i className={`bi bi-${taskStatus.icon}`} />
                          </span>{" "}
                          {taskStatus.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>{" "}
                <div className="btn-group" role="group" id="estimatedTimeContainer">
                  <button
                    type="button"
                    className={`btn dropdown-toggle estimatedTimeContainer estimated_${estimatedTimeClass}`}
                    data-bs-toggle={isShowEstimatedTime ? "dropdown" : ""}
                    aria-expanded="false"
                    onClick={() => showHideTaskDropdown(true, "estimatedTime")}
                  >
                    <i className="bi bi-clock" /> {estimatedTime}
                  </button>
                  <ul
                    className={
                      isShowEstimatedTime ? "dropdown-menu hiddenStatus show" : "dropdown-menu hiddenStatus"
                    }
                  >
                    {taskTimeData.map((taskTime, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setEstimatedTime(taskTime.LABEL)
                          setEstimatedTimeClass(taskTime.CLASS)
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => showHideTaskDropdown(false, "estimatedTime")}
                        >
                          <span className={`estimated_${taskTime.CLASS}`}>
                            <i className="bi bi-clock" />
                          </span>{" "}
                          {taskTime.LABEL}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>{" "}
                <div className="btn-group" role="group" id="urgencyStatusContainer">
                  <button
                    type="button"
                    className={`btn dropdown-toggle urgencyStatusContainer urgencyStatus_${urgencyClass}`}
                    data-bs-toggle={isShowUrgency ? "dropdown" : ""}
                    aria-expanded="false"
                    onClick={() => showHideTaskDropdown(true, "urgency")}
                  >
                    <i className={`bi bi-${urgencyIcon}`} /> {urgency}
                  </button>

                  <ul
                    className={
                      isShowUrgency ? "dropdown-menu hiddenStatus show" : "dropdown-menu hiddenStatus"
                    }
                  >
                    {taskUrgencyData.map((taskUrgency, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setUrgency(taskUrgency.LABEL)
                          setUrgencyClass(taskUrgency.CLASS)
                          setUrgencyIcon(taskUrgency.ICON)
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => showHideTaskDropdown(false, "urgency")}
                        >
                          <span className={`urgencyStatus_${taskUrgency.CLASS}`}>
                            <i className={`bi bi-${taskUrgency.ICON}`} />
                          </span>{" "}
                          {taskUrgency.LABEL}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>{" "}
                <div className="btn-group">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Add New Person / Role</Tooltip>}>
                    <button
                      type="button"
                      className="btnAddNewGreenDashed"
                      id="btnAddNewPersonRole"
                      onClick={() => {
                        setIsDisplayNewPerson(!isDisplayNewPerson)
                      }}
                    >
                      <i className="bi bi-person-fill" />
                    </button>
                  </OverlayTrigger>
                </div>{" "}
                <div className="btn-group">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Add New Calendar</Tooltip>}>
                    <button
                      type="button"
                      className="btnAddNewGreenDashed"
                      id="btnAddNewTaskEventTime"
                      onClick={eventDateTimePicker}
                    >
                      <i className="bi bi-calendar-plus" />
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            {isDisplayNewPerson && (
              <TaskContactForm
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                defaultSelectedRole={defaultSelectedRole}
                setDefaultSelectedRole={setDefaultSelectedRole}
                setIsDisplayNewPerson={setIsDisplayNewPerson}
                setSelectedPhoneType={setSelectedPhoneType}
                setSelectedAddressType={setSelectedAddressType}
                existingContactId={existingContactId}
                setExistingContactId={setExistingContactId}
                defaultUserOptionSelected={defaultUserOptionSelected}
                setDefaultUserOptionSelected={setDefaultUserOptionSelected}
                address={address}
                setAddress={setAddress}
                tempContactName={tempContactName}
                profilePic={profilePic}
              />
            )}

            <div className="row g-0 mt-3 addNewTaskEventTimeRow">
              <div className="col-lg-12 position-relative">
                {isEventDateVisible && (
                  <input
                    name="eventTime"
                    type="datetime-local"
                    className="form-control form-control-lg addNewTaskEventDateTime"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-bs-dismiss="modal"
              onClick={() => handleClose()}
            >
              <i className="bi bi-x-lg" /> Close
            </button>
            <button type="submit" className="btn btn-secondary" name="add-new">
              <i className="bi bi-check2" /> Add <i className="bi bi-list-ul" />
            </button>
            <button type="submit" className="btn btn-primary" name="add-new-with-detail">
              <i className="bi bi-check2" /> Create <i className="bi bi-box-arrow-in-right" />
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddTaskModalForContact
